import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { fetchPosts } from '../../statesManagements/actions/postActions'
import UsePagination from "../toolkits/usePagination";
import Loader from '../toolkits/loader';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
type Props = {
    inputValue: string
}

const Hot: React.FC<Props> = ({ inputValue }) => {
    const arrowRight = faArrowRight as IconProp;
    const arrowLeft = faArrowLeft as IconProp;
    const dispatch = useDispatch();
    const selector = useSelector((state: any) => state.posts);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(25);
    const [nextPage, setNextPage] = useState(1);

    const handlePageChange = () => {
   
        // setNextPage(nextPage + 1);
        fetchPosts(dispatch, nextPage + 1);
        window.scrollTo({
            top: 240,
            behavior: 'smooth',
        });

    }
    const handlePageChange2 = () => {
       
        fetchPosts(dispatch, nextPage - 1);
        window.scrollTo({
            top: 240,
            behavior: 'smooth',
        });
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selector.posts.slice(indexOfFirstItem, indexOfLastItem);
    //slice first 20 trings

    const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

    return (
        <div>
            <div className="my-2 p-4">
                {/* back btn */}
                <h1>
                    <NavLink to="/" className="text-decoration-none text-dark">
                        <FontAwesomeIcon icon={arrowLeft} className="mr-2" />
                        <span className="text-dark">Home</span>
                    </NavLink>
                </h1>
            </div>
            <h1 className='text-xl  store font-bold pl-16'>Top Selling 🔥</h1>
            <div id='' className='flex flex-col items-center'>

                {/* if selector is empty */}
                {selector.posts.length === 0 ? <div><Loader /></div> :
                    <div id='ctn' className='ctn flex justify-center items-center'>
                        {currentItems.map((post: any, index: any) => {
                            return (
                                <div className='cards px-3 mx-1  '>
                                    <div className=' flex flex-col justify-center items-center' >
                                        <NavLink to={'/view_product/' + post.productURLId} key={index.productId} className="flex flex-col items-center justify-center">

                                            <div className="bg-white img-ctn p-3 flex flex-col justify-center">
                                                <div className="off mb-1">
                                                    <p className='text-sm font-bold tred text-red-500'>{Math.round((parseInt(post.dealPrice.toString().replace(/\D/g, '')) - parseInt(post.originalPrice.toString().replace(/\D/g, ''))) / parseInt(post.originalPrice.toString().replace(/\D/g, '')) * 100)}% off</p>
                                                </div>
                                                <img className='hover:shadow-2xl mx-2 imgs' src={post.imageUrls} alt="" />
                                            </div>
                                            <div className='cards-text uppercase text-center'>
                                                <h1 className='text-center font-semibold  tracking-wider  rounded my-1 text-md font-base'>{post.brandName}</h1>
                                                <a title={post.productTitle} className='productTitle  text-sm my-2'>{post.productTitle.slice(0, 45)}...</a>
                                                
                                                <div className="flex  justify-between my-1">
                                                  
                                                    <p className='text-base lt line-through pb-1'>{post.originalPrice}</p>
                                                    <p className='font-bold text-sm' style={{ "color": "#637d68" }}>{post.dealPrice}</p>
                                                </div>
                                              
                                            </div>

                                        </NavLink>
                                        <div className='w-full'>

                                            <button style={{ "backgroundColor": "#7f9481" }} className='cards-btn w-full  shadow p-1 rounded text-white '><a href={post.productUrl} target="_blank">Get Offer</a></button>
                                        </div>
                                    </div>
                                </div>
                            )

                        }
                        )}


                    </div>
                }
                <div className=" pagination p-4 flex items-center justify-center">
                    <button className={`page-btn text-white mx-5 py-1 w-20 text-lg' ${nextPage < 1 ? "paginate-btn-fade mx-5 py-1 px-5 text-lg" : "paginate-btn mx-5 py-1 px-5 text-lg"}`} onClick={handlePageChange2} disabled={nextPage < 1}>
                        <FontAwesomeIcon icon={arrowLeft} className="text-lg" />
                    </button>
                    < UsePagination itemsPerPage={itemsPerPage} totalItems={selector.posts.length} paginate={paginate} />

                    <button style={{ "backgroundColor": "#637d68" }} className='page-btn  text-white mx-5  py-1 w-20 text-lg' onClick={handlePageChange} >
                        <FontAwesomeIcon icon={arrowRight} className="text-lg" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Hot;
