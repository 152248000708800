import brands1 from "../../../src/assets/brandsImg/boat.jpg"
import brands2 from "../../../src/assets/brandsImg/2153869-removebg-preview.png"
import brands3 from "../../../src/assets/brandsImg/apple-logo-brand-iphone-8-png-favpng-kpjuE97YAJfvw2kincmpHbXii-removebg-preview.png"
import brands4 from "../../../src/assets/brandsImg/Color-Pampers-Logo-removebg-preview.png"
import brands5 from "../../../src/assets/brandsImg/dove.webp"
import brands6 from "../../../src/assets/brandsImg/images-removebg-preview (4).png"
import brands7 from "../../../src/assets/brandsImg/images-removebg-preview (5).png"
import brands8 from "../../../src/assets/brandsImg/images-removebg-preview (6).png"
import brands9 from "../../../src/assets/brandsImg/images-removebg-preview (7).png"
import brands10 from "../../../src/assets/brandsImg/images-removebg-preview (8).png"
import brands11 from "../../../src/assets/brandsImg/images-removebg-preview (9).png"
import brands12 from "../../../src/assets/brandsImg/images-removebg-preview (10).png"
import brands13 from "../../../src/assets/brandsImg/LOreal-Emblem-removebg-preview.png"
import brands14 from "../../../src/assets/brandsImg/nivea.png"
import brands15 from "../../../src/assets/brandsImg/OnePlus-One-Plus-Logo-Roofing-2-removebg-preview.png"
import brands16 from "../../../src/assets/brandsImg/oppo.jpg"
import brands17 from "../../../src/assets/brandsImg/png-clipart-adidas-originals-t-shirt-foot-locker-logo-adidas-text-logo-removebg-preview.png"
import brands18 from "../../../src/assets/brandsImg/s1-55-removebg-preview.png"
import brands19 from "../../../src/assets/brandsImg/SKYBAGS-LOGO-PNG-removebg-preview.png"
import brands20 from "../../../src/assets/brandsImg/vivo-smartphone-logo-with-BG-CTC-1-e1446015885545-1024x794-removebg-preview.png"
import brands21 from "../../../src/assets/brandsImg/Xiaomi-Logo-2019-present-removebg-preview.png"

import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../homepage/Header"
import Footer from "../homepage/footer"

type Props = {}

const Brands: React.FC<Props> = () => {
    return (
        <div className=" ">
            <Header />
            <div className="mt-10 brands p-5">
                <h1 className='font-bold text-xl pl-10'>Brands</h1>
                <div className='p-4 flex flex-row flex-wrap justify-evenly box-border items-center h-fit'>
                    <div className='flex my-5 items-center  w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                        <img className="w-32 h-20" src={brands1} alt="" />
                        <hr />
                        <h1 className='font-bold text-lg mt-2 px-10'>Boat</h1>
                    </div>
                    <div className='flex  items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                        <img className="w-32 h-20" src={brands2} alt="" />
                        <hr />
                        <h1 className='font-bold text-md mt-2 px-1'>Fastrack</h1>
                    </div>
                    <div className='flex  items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                        <img className="w-32 h-20" src={brands3} alt="" />
                        <hr />
                        <h1 className='font-bold text-lg mt-2 px-10'>Apple</h1>
                    </div>
                    <div className='flex  items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                        <img className="w-32 h-20" src={brands4} alt="" />
                        <hr />
                        <h1 className='font-bold text-lg mt-2 px-10'>Pampers</h1>
                    </div>
                    <div className='flex  items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                        <img className="w-32 h-20" src={brands5} alt="" />
                        <hr />
                        <h1 className='font-bold text-lg mt-2 px-10'>Dove</h1>
                    </div>
                    <div className='flex  items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                        <img className="w-32 h-20" src={brands6} alt="" />
                        <hr />
                        <h1 className='font-bold text-lg mt-2 px-10'>Lakme</h1>
                    </div>

                    <div className='flex  items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                        <img className="w-32 h-20" src={brands7} alt="" />
                        <hr />
                        <h1 className='font-bold text-lg mt-2 px-10'>Puma</h1>
                    </div>
                    <div className='flex  items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                        <img className="w-32 h-20" src={brands8} alt="" />
                        <hr />
                        <h1 className='font-bold text-lg mt-2 px-10'>Sketchers</h1>
                    </div>
                    <div className='flex  items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                        <img className="w-32 h-20" src={brands9} alt="" />
                        <hr />
                        <h1 className='font-bold text-lg mt-2 px-10'>Reebok</h1>
                    </div>
                    <div className='flex  items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                        <img className="w-32 h-20" src={brands10} alt="" />
                        <hr />
                        <h1 className='font-bold text-lg mt-2 px-10'>Beta</h1>
                    </div>
                </div>
            </div>

            <div className="p-10">
                <h1 className="font-bold text-2xl my-4">Our Big Online Stores</h1>
                <p>
                    We are a dedicated team of online shopping experts, every day we try our best to get you the most affordable shopping deals from top Indian eCommerce stores such as Amazon, Flipkart, Tata Cliq, Ajio, Pepperfry, and Myntra, etc.
                </p>
            </div>
            <Footer />
        </div>
    )
}
export default Brands;
