import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';
import { fetchBeautyCategories } from '../../statesManagements/actions/categoriesActions';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Footer from '../homepage/footer';
import Header from '../homepage/Header';

// import {FaceBookButton} from "react-social"
// import { FacebookButton, FacebookCount } from "react-social";
// import Categories from '../pages/categories';


type Props = {}
const Beauty: React.FC<Props> = () => {
    const watch1 = faStopwatch as IconProp;

    const dispatch = useDispatch();
    const selector = useSelector((state: any) => state.categories);
    useEffect(() => {
        fetchBeautyCategories(dispatch);
        // console.log("check",selector);
    }, []);

    return (
        <div className="bg-slate-50 py-10 pl-10 flex flex-col">
            <Header />

            <h1 className=' mt-10 text-xl font-bold pl-16'>Beauty & Personal Care</h1>

            <div id='' className='flex flex-col items-center'>
                <div id='ctn' className='ctn flex justify-center items-center'>
                    {selector.categories.map((post: any, index: any) => {
                        return (
                            <NavLink to={'/view_product/' + post.productURLId} className='cards mx-1 p-5  '>
                                <div className=' flex justify-center items-center' >
                                    <div key={index.productId} className="flex flex-col items-center justify-center">

                                        <div className="bg-white img-ctn p-3 flex flex-col justify-center">
                                            <div className="off mb-1">
                                                <p className='text-sm font-bold tred text-red-500'>{Math.round((parseInt(post.dealPrice.toString().replace(/\D/g, '')) - parseInt(post.originalPrice.toString().replace(/\D/g, ''))) / parseInt(post.originalPrice.toString().replace(/\D/g, '')) * 100)}% off</p>
                                            </div>
                                            <img className='hover:shadow-2xl mx-2 imgs' src={post.imageUrls} alt="" />
                                        </div>
                                        <div className='cards-text uppercase text-center'>
                                            <h1 className='text-center font-semibold my-1 tracking-wider  rounded my-1 text-md font-base'>{post.brandName}</h1>
                                            <a title={post.productTitle} className='productTitle  text-sm my-2'>{post.productTitle.slice(0, 40)}...</a>
                                            {/* <input type="text" className='productTitle  text-sm my-2' value={post.productTitle} /> */}
                                            <div className="flex  justify-between my-1">
                                                {/* <p className='text-xs  text-gray-500'> <FontAwesomeIcon icon={watch1} className="text-xs mr-1" />{Math.round((Number(post.dealPostTime) / (1000 * 60 * 60)) % 24)} hours ago</p> */}
                                                <p className='text-base lt line-through pb-1'>{post.originalPrice}</p>
                                                <p className='font-bold text-sm' style={{ "color": "#637d68" }}>{post.dealPrice}</p>
                                            </div>
                                            <div className="flex justify-between my-1">
                                                {/* <p className='text-xs font-bold  text-red-500'>{Math.round((parseInt(post.dealPrice.toString().replace(/\D/g, '')) - parseInt(post.originalPrice.toString().replace(/\D/g, ''))) / parseInt(post.originalPrice.toString().replace(/\D/g, '')) * 100)}% off</p> */}
                                                {/* <p className='text-base line-through pb-1'>{post.originalPrice}</p> */}
                                            </div>
                                        </div>
                                        <div className='text-center'>

                                            {/* <button style={{ "backgroundColor": "#637d68" }} className='cards-btn w-40  shadow p-1.5 rounded text-white '><a href={post.productUrl} target="_blank">Get Offer</a></button> */}
                                        </div>
                                        {/* <div className="share flex my-2">
                                            <TwitterShareButton className='mx-1' url={post.productUrl} title={post.productTitle} hashtags={["eCommerce,amazon"]} >
                                                <TwitterIcon size={20} round />
                                            </TwitterShareButton>
                                            <FacebookShareButton url={post.productUrl} quote={post.productTitle}>
                                                <FacebookIcon size={20} round />
                                            </FacebookShareButton>
                                            <WhatsappShareButton className='mx-1' url={post.productUrl} title={post.productTitle}>
                                                <WhatsappIcon size={20} round />
                                            </WhatsappShareButton>
                                        </div> */}

                                    </div>
                                </div>
                            </NavLink>
                        )

                    }
                    )}


                </div>
            </div>
            <Footer />
        </div>
    );
}
export default Beauty;