import { FETCH_POSTS } from "./types";
import * as types from "../actionTypes"

// fetch posts and append page number from js file 
export const fetchPosts = async (dispatch, number) => {
    try {
        // console.log("fetcher",search)
        dispatch({type: types.FETCH_POSTS.REQUEST})
        const fetchdata=await fetch(`${process.env.REACT_APP_API_URL}/products/page/${number}`,{
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        const data=await fetchdata.json()
        // console.log(data)
        dispatch({
            type: types.FETCH_POSTS.SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({type: types.FETCH_POSTS.FAILURE, payload: error})
        // console.error(error)
    }
}
export const searchPosts = async (dispatch, number, search) => {
    try {
        // console.log("fetcher",search)
        dispatch({type: types.FETCH_POSTS.REQUEST})
        const fetchdata=await fetch(`${process.env.REACT_APP_API_URL}/${search}/page/${number}`,{
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        const data=await fetchdata.json()
        // console.log(data)
        dispatch({
            type: types.FETCH_POSTS.SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({type: types.FETCH_POSTS.FAILURE, payload: error})
        // console.error(error)
    }
}

