import { type } from "os";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchGiftIdeas } from "../../statesManagements/actions/categoriesActions";
import Loader from "../toolkits/loader";
import { useParams } from "react-router-dom";

type Props = {};

const GiftIdeas: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state: any) => state.categories);
  const { giftIdeasLoading, giftIdeasErr } = selector;
  const [gifts, setGifts] = useState<any>([]);
  const [showMore, setShowMore] = useState(false);
  const [showText, setShowText] = useState("Show More ↓");
  const [clickedIndx, setClickedIndx] = useState<number>(0);
  const { title } = useParams();
  const handleShowMore = (indx) => {
    setClickedIndx(indx);
    if (showMore && indx === clickedIndx) {
      setShowText("Show More ↓");
      setShowMore(false);
    } else {
      setShowText("Show Less ↑");
      setShowMore(true);
    }
  };

  const openProductUrl = (url: string) => {
    window.open(url, "_blank");
  };

  const getDiscountOff = (price: string, discount: string) => {
    // result should return in this format 50% off
    // remove all characters except numbers and decimal and convert to number
    const santizedPrice = price.replace(/[^0-9.-]+/g, "");
    const santizedDiscount = discount.replace(/[^0-9.-]+/g, "");
    const discountOff =
      (parseInt(santizedDiscount) / parseInt(santizedPrice)) * 100;
    return `${Math.round(discountOff)}% off`;
  };

  const detectStoreFromProductUrl = (url: string) => {
    // result should check the url and return the store name
    // eg. https://www.jumia.com.ng/lenovo-ideapad-s145-15-6-inch-laptop-intel-core-i3-1005g1-4gb-ram-1tb-hdd-2gb-graphics-win-10-grey-100005000.html
    // should return On Jumia
    // eg https://www.amazon.in/dp/B0B5LVS732?tag=bbmv0b-21
    // should return On Amazon

    const store = url.split(".")[1];
    return `On ${store.charAt(0).toUpperCase() + store.slice(1)}`;
  };
  useEffect(() => {
    // replace the space in the title with a dash or %20 and  make it lowercase
    let newTitle = title.replace(/ /g, "-").toLowerCase();
    fetchGiftIdeas(dispatch, newTitle);
  }, [dispatch, title]);

  useEffect(() => {
    setGifts(selector.giftIdeas);
  }, [selector.giftIdeas]);

  useEffect(() => {
    // setProducts(selector.dynamicProducts);
  }, [clickedIndx, showMore]);

  useEffect(() => {}, [gifts?.imageUrls]);

  return (
    <div className="p-2 lg:p-5">
      {giftIdeasLoading ? (
        <Loader />
      ) : giftIdeasErr ? (
        <div className="text-center text-red-500">Error loading gift ideas</div>
      ) : (
        <>
          <div
            style={{ backgroundColor: "#637d68" }}
            className=" h-auto lg:h-24 p-4 rounded-lg flex justify-start items-center"
          >
            <h1 className=" text-white font-bold text-xl ">
              Gifts are a great way to show your appreciation for someone.{" "}
              <br />
              Have a look at our gift ideas for your loved ones.
            </h1>
          </div>

          {gifts && gifts?.length > 1 ? (
            <>
              <div className=" mt-10 flex flex-col justify-center">
                {gifts?.map((gift: any, index) => {
                  return (
                    <div className="my-4 bg-white shadow-xl rounded-xl">
                      <div className=" flex flex-wrap flex-col lg:flex-row justify-between p-5">
                        <div>
                          {gift?.imageUrls?.map((url: string) => {
                            return (
                              <img
                                src={url}
                                alt="product"
                                className="w-20 h-24 object-contain"
                              />
                            );
                          })}
                        </div>

                        <div className="flex flex-col items-start justify-center">
                          <p className="capitalize font-bold">
                            {gift?.productTitle.slice(0, 55)}..
                          </p>
                          <p className="text-md my-3 capitalize text-gray-500">
                            {gift?.brandName}
                          </p>
                          <label className="bg-tosbp-green text-sm text-white rounded-md font-semibold p-1">
                            {getDiscountOff(
                              gift?.originalPrice,
                              gift?.dealPrice
                            )}
                          </label>
                        </div>

                        <div className="flex flex-col-reverse justify-center items-center">
                          <p className="font-semibold">
                            {gift?.totalRatings} in total
                          </p>
                          <p className="text-xs text-gray-500">
                            Rated {gift?.ratings} ⭐
                          </p>
                        </div>

                        <div className="flex flex-col items-end justify-center">
                          <div className="flex my-2 lg:my-0 flex-wrap flex-col">
                            <div className="w-full">
                              <button
                                onClick={() => openProductUrl(gift?.productUrl)}
                                className="w-full bg-tosbp-green text-white px-5 py-2 rounded-md font-bold"
                              >
                                View Deal
                              </button>
                            </div>
                            <div className="flex flex-wrap items-center mt-4">
                              {/* <p className="mx-2 font-bold text-sm ittalics">
                                {detectStoreFromProductUrl(gift?.productUrl)}
                              </p> */}
                              {/* show more button */}
                              <p
                                onClick={() => {
                                  handleShowMore(index);
                                }}
                                className="mx-2 cursor-pointer"
                              >
                                {showMore && clickedIndx === index
                                  ? showText
                                  : "Show More ↓"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {showMore && clickedIndx === index ? (
                        <div className="my-2 p-5">
                          <div className="flex flex-col lg:flex-row justify-between  items-center lg:items-center">
                            <div className="w-auto h-96 overflow-y-scroll lg:w-[50%] flex flex-col justify-start items-start">
                              <ul>
                                {gift?.descriptionList.map(
                                  (feature: string) => {
                                    return (
                                      <li className="flex justify-between p-2">
                                        ✓ {feature}
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>

                            <div className="w-auto h-full lg:w-[50%] flex flex-col justify-center items-center">
                              <div className="my-2 flex flex-col justify-end items-end">
                                {/* image */}
                                {gift?.imageUrls?.map((url: string) => {
                                  return (
                                    <img
                                      src={url}
                                      alt="product"
                                      className="w-40 h-40 lg:w-96 lg:h-96"
                                    />
                                  );
                                })}
                              </div>
                              <div className="flex items-center">
                                {/* original price */}
                                <p className="text-sm text-gray-500 line-through">
                                  {gift?.originalPrice}
                                </p>

                                <label className="bg-tosbp-green mx-3 text-sm text-white rounded-md font-semibold p-1">
                                  {getDiscountOff(
                                    gift?.originalPrice,
                                    gift?.dealPrice
                                  )}
                                </label>

                                <p className="mx-2 font-bold text-sm ittalics">
                                  {detectStoreFromProductUrl(gift?.productUrl)}
                                </p>
                              </div>

                              <button
                                onClick={() => openProductUrl(gift?.productUrl)}
                                className="w-full lg:w-[50%] mt-4 font-semibold border border-tosbp-bg border-2 rounded-md py-2 px-3"
                              >
                                View
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className="my-2 p-5">
                {/* empty state noticce */}
                <p className="text-center text-gray-500">
                  {" "}
                  Ooops no details to show
                </p>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default GiftIdeas;
