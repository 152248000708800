import React from 'react'

const UsePagination = ({ itemsPerPage, totalItems, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }
    return (
        <div>
            <ul className="pagination flex items-center justofy-center flex-row">
                {pageNumbers.map(number => (
                    <li key={number} className="page-item m-2 text-lg px-2 p-1 ">
                        <a onClick={() => paginate(number)} className="page-link" href="/">{number}</a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default UsePagination
