import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTwitter, faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
type Props = {}

const Footer: React.FC<Props> = () => {
    const twitter = faTwitter as IconProp;
    const instagram = faInstagram as IconProp;
    const facbook = faFacebook as IconProp
    //get year
    const year = new Date().getFullYear();
    return (
        <div>
            <div className="footer p-5 md:p-10 pb-4 " style={{ "backgroundColor": "#637d68" }}>
                <div className="texts my-6 text-white text-center">
                    <div className="my-6">
                        <h1 className="text-2xl md:text-4xl my-3 font-extrabold">
                            <span  className="mx-1 text-emerald-400">
                                <NavLink to="/">
                                impoffers.com
                                </NavLink>
                            </span>
                            is India's Largest Online Sales Portal.
                        </h1>
                        <p className="my-2">
                            Tosbp is India's largest online shopping deal, offer, and coupon portal.
                            Where our shopping experts publish handpicked deals and offers from India's top stores such as Amazon,
                            Flipkart, Tata Cliq, and Ajio on a regular basis. Online shoppers
                            can find the most recent online deals, daily hot deals, heavily discounted deals,
                            quiz offers, freebies offers, voucher codes, discount coupons, mobile phone deals,
                            deals of the day offers, and recharger offers on our website.
                        </p>
                    </div>
                    <div className="my-6">
                        <h1 className="font-bold text-2xl my-2">
                            Always start to pay less! Start buying more!
                        </h1>
                        <p>
                            In this advanced era of the internet shopping, we frequently come across
                            highly discounted products from top Indian online stores, where you can get up to 100% off,
                            almost free. We work around the clock to bring you lower-priced products in comparison to other stores.
                        </p>
                    </div>
                    <div className="my-6">
                        <h1 className="font-bold text-2xl my-2">
                            Handpicked Online Deals & Offers 24 hours a day, 7 days a week
                        </h1>
                        <p>
                            Our buying specialists handpick and publish every
                            bargain you see on our website for
                            a better online shopping experience.
                            Our online consumers will not find any
                            spammy discounts, offers, or coupons here;
                            only legitimate deals are published by our deal hunters.
                        </p>
                    </div>
                </div>
                <div className="links text-white flex justify-around ">
                    <div className="socials">
                        <h1 className="font-bold text-xl my-2">Links</h1>
                        <ul>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Home
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Terms & Conditions
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Privacy Policy
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/disclaimer">
                                    Disclaimer
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Credits
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Feedback
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    site map
                                </NavLink>
                            </li>
                        </ul>

                    </div>
                    <div className="socials">
                        <h1 className="font-bold text-xl my-2">Top Categories</h1>
                        <ul>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/beauty">
                                    Beauty & Personal Care
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/fashion">
                                    Fashion
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/mobile">
                                    Home & Kitchen
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/electronics">
                                    Electronics
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/health">
                                    Health & Beauty
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/computer">
                                    Computers & Laptops
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/shoes-handbag">
                                    Shoes & Bags
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/grocery">
                                    Grocery
                                </NavLink>
                            </li>
                        </ul>

                    </div>
                    <div className="socials">
                        <h1 className="font-bold text-md my-2">Stores</h1>
                        <ul>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Amazon
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Flipkart
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Boat
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Fastrack
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Apple
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Pampers
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Dove
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Lakme
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Puma
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Sketchers
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Reebok
                                </NavLink>
                            </li>
                            <li className="p-1 text-sm pl-0">
                                <NavLink to="/">
                                    Beta
                                </NavLink>
                            </li>
                        </ul>

                    </div>
                </div>
                <div className="Flex justify-between text-white">
                    <FontAwesomeIcon className="p-3 pl-0 text-xl" icon={twitter} />
                    <FontAwesomeIcon className="p-3 pl=0 text-xl" icon={faFacebook} />
                    <FontAwesomeIcon className="p-3 pl=0 text-xl" icon={instagram} />
                </div>
                <h1 className="text-white">All trademarks, trade names and logos appearing on this site are the property of the respective owners.</h1>
            </div>
            <div className="text-center text-sm md:text-lg p-1 md:p-3 font-bold">
                <p>
                    Copyright © {year} 
                    <span  className="mx-1 text-emerald-400"> 
                    <NavLink to="/">impoffers.com </NavLink> 
                    </span>
                    | All rights reserved.
                </p>
            </div>
        </div>
    );
}

export default Footer;