import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Navigate, useNavigate } from "react-router-dom";
import SideBar from "../layout/sideBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import AOS from "aos";
import "aos/dist/aos.css";
type Props = {
  children: React.ReactNode;
};
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

 function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const AppLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const bar = faBars as IconProp;
  const [showNav, setShowNaV] = useState(true);
  const [widths, setWidth] = useState("ml-48");
  const [openBanner, setOpenBanner] = useState(true);
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();

  const handleCloseBanner = () => {
    setOpenBanner(false);
  };

  const navigateToDiscliamer = () => {
    navigate("/disclaimer");
    setOpenBanner(false);
  };

  const handleToggle = () => {
    setShowNaV(!showNav)
    setWidth(showNav === true ? " pl-4 px-2" : "ml-0 pl-0 px-0")
  }

  useEffect(() => {
    setTimeout(() => {
      setOpenBanner(false);
    }, 90000);
  }, [showNav]);

  useEffect(() => {
    // console.log(showNav)
    if(width < 1024){
      setShowNaV(false)
    }
  }, [showNav, width]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease",
    });
    AOS.refresh();
  }, []);


  const isAuthPage =
    location.pathname?.startsWith("/auth") ||
    location.pathname === "/view-product" ||
    location.pathname === "/gift-ideas";
  return (
    <div className="flex">
      <div className={`flex ${showNav === true ? 'w-[16%]' : 'w-[0%]'}`}>
        {showNav && !isAuthPage && <SideBar />}
        {!isAuthPage && <FontAwesomeIcon onClick={() => {handleToggle()}} className="side-toggle z-10 pl-6 fixed p-10" icon={bar} />}
      </div>
      <div className={` ${showNav === true ? 'lg:w-[84%]' : 'w-full'}  child`}>
        {children}
      </div>

      {/* banner to show disclaimer of partcipating in amaxon sale */}
      {
        openBanner && (
          <>
            <div style={{ "backgroundColor": "#637d68" }} className="p-3 z-50 fixed top-0 w-full slideDownfromTop bottom-90">
              <div className="banner-content">
                {/* close modal */}
                <div className="flex justify-end">
                  <button 
                    onClick={handleCloseBanner}
                  className="text-white text-xs font-bold">Close</button>
                </div>

                <p className="text-center text-white text-md  lg:text-xl my-2 font-bold">  🚨 Important Disclaimer 🚨</p>
                <p className="text-center text-white text-sm lg:text-md font-semibold">

                  <span className="mx-1 text-emerald-400 text-xl">
                    <NavLink to="/">
                      impoffers.com
                    </NavLink>
                  </span> is a  participant in the Amazon Services LLC Associates Program, an affiliate advertising program de signed to provide a means for us to earn fees by linking to <NavLink to="http://amazon.in/">Amazon.in</NavLink> and affiliated sites.
                  <span
                  onClick={navigateToDiscliamer} 
                  className="mx-1 cursor-pointer text-emerald-400 underline text-md">
                    Read More
                  </span>
                </p>
              </div>
            </div>
          </>
        )
      }
    </div>

  );
};

export default AppLayout;