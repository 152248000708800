import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faArrowAltCircleDown, faShirt } from "@fortawesome/free-solid-svg-icons";
import { faFaceRollingEyes } from "@fortawesome/free-solid-svg-icons";
import { faPlateWheat } from "@fortawesome/free-solid-svg-icons";
import { faTv } from "@fortawesome/free-solid-svg-icons";
import { faShoePrints } from "@fortawesome/free-solid-svg-icons";
import { faHeadphones } from "@fortawesome/free-solid-svg-icons";
import { faArrowCircleDown, faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import tsbsplogo from "../assets/tosbpLogo.png";
//imoort electronics from assets folder
// import navlink
import { NavLink } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
// import { DebouncerInput } from "@boluwatifee4/react-input-value-debouncer";

type Props = {}

const SideBar: React.FC<Props> = () => {
    const shirt = faShirt as IconProp;
    const face = faFaceRollingEyes as IconProp;
    const wheat = faPlateWheat as IconProp;
    const tv = faTv as IconProp;
    const shoe = faShoePrints as IconProp;
    const headphone = faHeadphones as IconProp;
    const arrow = faArrowAltCircleDown as IconProp;
    const arrowDown = faArrowCircleUp as IconProp;
    const [dropDown, setDropDown] = React.useState(false);

    const handleDropDown = () => {
        setDropDown(!dropDown);
    }


    return (
        <div style={{ backgroundColor: "#f5faf8" }} id="leftCol" className="p-3 cursor-pointer ">
            <div className="logo flex items-center justify-center">
                <img src={tsbsplogo} className="w-20 rounded-full" alt="logo" />
            </div>
            <h1 className="mx-3 my-4 mt-10 text-md font-bold">Explore</h1>
            {/* <DebouncerInput
                delay={1000}
                onChange={(e) => console.log(e?.target?.value)}
                placeholder="Search"
                className="w-full p-2 mx-3 my-2 border border-gray-300 rounded-md"
            /> */}
            <ul>

                <li className="p-2 text-base border-b border-black font-semibold">
                    {/* <span className="mx-2 text-blue-500"><FontAwesomeIcon icon={shirt} /></span> */}
                    <NavLink to="/"
                    // className={(isActive: Boolean) =>
                    //     "nav-link" + (isActive ? "active" : "bg-red")
                    // }
                    >
                        Home
                    </NavLink>
                </li>
                <li onClick={handleDropDown} className="p-2 text-lg">
                    <p className="flex justify-between"> Categories <span>{dropDown ? <FontAwesomeIcon icon={arrowDown} /> : <FontAwesomeIcon icon={arrow} />}</span></p>
                </li>
                {dropDown ?

                    <div className={` ' ${dropDown ? "dropDown" : "dropOff"}`}>
                        <li className="p-2 text-xs font-semibold">
                            <span className="mx-2 text-blue-500"><FontAwesomeIcon icon={shirt} /></span>
                            <NavLink to="/fashion"
                            // className={(isActive: Boolean) =>
                            //     "nav-link" + (isActive ? "active" : "bg-red")
                            // }
                            >
                                Fashion
                            </NavLink>
                        </li>
                        <li className="p-2 text-xs font-semibold">
                            <span className="mx-2 text-orange-500"><FontAwesomeIcon icon={face} /></span>
                            <NavLink to="/beauty"
                            // className={(isActive: Boolean) =>
                            //     "nav-link" + (isActive ? "active" : "bg-red")
                            // }
                            >
                                Beauty & Personal Care
                            </NavLink>
                        </li>
                        <li className="p-2 text-xs font-semibold">
                            <span className="mx-2 text-blue-500"><FontAwesomeIcon icon={wheat} /></span>
                            <NavLink to="/mobile"
                            // className={(isActive: Boolean) =>
                            //     "nav-link" + (isActive ? "active" : "bg-red")
                            // }
                            >
                                Home & Kitchen
                            </NavLink>
                        </li>
                        <li className="p-2 text-xs font-semibold">
                            <span className="mx-2 text-blue-500"><FontAwesomeIcon icon={tv} /></span>
                            <NavLink to="/electronics"
                            // className={(isActive: Boolean) =>
                            //     "nav-link" + (isActive ? "active" : "bg-red")
                            // }
                            >
                                Electronics
                            </NavLink>
                        </li>
                        <li className="p-2 text-xs font-semibold">
                            <span className="mx-2 text-blue-500">💊</span>
                            <NavLink to="/health"
                            // className={(isActive: Boolean) =>
                            //     "nav-link" + (isActive ? "active" : "bg-red")
                            // }
                            >
                                Health
                            </NavLink>
                        </li>
                        <li className="p-2 text-xs font-semibold">
                            <span className="mx-2 text-blue-500">💻</span>
                            <NavLink to="/computer"
                            // className={(isActive: Boolean) =>
                            //     "nav-link" + (isActive ? "active" : "bg-red")
                            // }
                            >

                                Computer Accessories
                            </NavLink>
                        </li>
                        <li className="p-2 text-xs font-semibold">
                            <span className="mx-2 text-blue-500">👟</span>
                            <NavLink to="/shoes-handbag"
                            // className={(isActive: Boolean) =>
                            //     "nav-link" + (isActive ? "active" : "bg-red")
                            // }
                            >
                                Shoes & Bags
                            </NavLink>
                        </li>
                        <li className="p-2 text-xs font-semibold">
                            <span className="mx-2 text-blue-500">🛒</span>
                            <NavLink to="/grocery"
                            // className={(isActive: Boolean) =>
                            //     "nav-link" + (isActive ? "active" : "bg-red")
                            // }
                            >
                                Groceries
                            </NavLink>
                        </li>
                    </div>

                    : null}
                {/*  <li className="p-2 text-xs font-semibold">
               <span className="mx-2 text-blue-500"><FontAwesomeIcon icon={shirt} /></span>
                    <NavLink to="/"
                        // className={(isActive: Boolean) =>
                        //     "nav-link" + (isActive ? "active" : "bg-red")
                        // }
                    >
                        Mobile Accessories
                    </NavLink>
                </li>
                
               
                <li className="p-2 text-xs font-semibold">
               <span className="mx-2 text-blue-500"><FontAwesomeIcon icon={shirt} /></span>
                    <NavLink to="/"
                        // className={(isActive: Boolean) =>
                        //     "nav-link" + (isActive ? "active" : "bg-red")
                        // }
                    >
                        Home Appliances
                    </NavLink>
                </li>
               
                <li className="p-2 text-xs font-semibold">
               <span className="mx-2 text-blue-500"><FontAwesomeIcon icon={shirt} /></span>
                    <NavLink to="/"
                        //  className={(isActive: Boolean) =>
                        //     "nav-link" + (isActive ? "b" : "bg-red")
                        // }
                    >
                        About
                    </NavLink>
                </li>
                <li className="p-2 text-xs font-semibold ">
               <span className="mx-2 text-blue-500"><FontAwesomeIcon icon={shirt} /></span>
                    <NavLink to="/"
                        // className={(isActive: Boolean) =>
                        //     "nav-link" + (isActive ? "active" : "bg-red")
                        // }
                    >
                        About
                    </NavLink>
                </li> */}
            </ul>
        </div>
    );
}

export default SideBar;