import React from "react";
import Header from "../homepage/Header";
import Hero from "../homepage/Hero";
import Body from "../homepage/Body";
import Footer from "./footer";
type Props = {}
const Homepage: React.FC<Props> = () => {
    return (
        <div className="homepage  flex flex-col">
            {/* <h1 className="text-center font-bold mbv">Mobile view coming soon....</h1> */}
            <Header />
            <Hero />
            <Body inputValue="" />
            <Footer />
        </div>
    );
    }

    export default Homepage;