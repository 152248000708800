import * as types from "../actionTypes"


const initialState = {
    posts: [],
    loading: false,
    error: null
}
export type ActionResponse = {
    type: string;
    payload: any;
  };
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default (state = initialState, { type, payload }: ActionResponse) => {
    switch (type) {
        case types.FETCH_POSTS.REQUEST:
            return {
                ...state,
                loading: true
            }
        case types.FETCH_POSTS.SUCCESS:
            return {
                ...state,
                posts: payload,
                loading: false
            }
            case types.FETCH_POSTS.FAILURE:
                return {
                    ...state,
                    error:payload,
                    loading: false  
                }
        default:
            return state;
    }
}