import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchPosts } from '../../statesManagements//actions/postActions';
import Loader from "../toolkits/loader";
import { NavLink } from "react-router-dom";
//react share
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    FacebookIcon,
    WhatsappIcon,
    TwitterIcon,
} from "react-share";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import categoriesReducer from "../../statesManagements/reducers/categoriesReducer";
import Header from "../homepage/Header";
import Footer from "../homepage/footer";

type Props = {}

interface Detail {
    productTitle: string;
    imageUrls: string[];
    dealPostTime: string;
    dealPrice: number;
    originalPrice: number;
    productUrl: string;
    ratings: number;
    categories: string[];
    brandName: string;
}

const ViewProduct: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const watch1 = faStopwatch as IconProp;
    const selector = useSelector((state: any) => state.posts);
    const [details, setDetails] = useState<Detail>({ productTitle: '', imageUrls: [], dealPostTime: '', dealPrice: 0, originalPrice: 0, productUrl: '', ratings: 0, categories: [], brandName: '' });
    const { id } = useParams(); //import from react-router-dom
    const [nextPage, setNextPage] = useState(3);
    const [searchValue, setSearchValue] = useState('123');
    const rater = Math.round(details.ratings);
    // replace money sign with empty so it can be parsed to number
    const dealPrice = details.dealPrice.toString().replace(/\D/g, '');
    const originalPrice = details.originalPrice.toString().replace(/\D/g, '');
    // get price discount percentage and convrt to number and round to whole number
    const discount = (parseInt(dealPrice) - parseInt(originalPrice)) / parseInt(originalPrice) * 100;
    // add space to each string in details.categories
    const category = details.categories.map(item => item.replace(/\s/g, ' '));
    //add className to each category

    const [categoriesRouter, setCategoriesRouter] = useState("")
    const category1 = category[0];
    //get second string in array
    const category2 = category[1];
    //get third string in array
    const category3 = category[2];
    //get fourth string in array
    const category4 = category[3];

    function setRouteUrl(route) {
        if (route === "Clothing & Accessories") {
            setCategoriesRouter("/fashion")

        } else if (route === "Home & Kitchen") {
            setCategoriesRouter("/mobile")
        } else if (route === "Electronics") {
            setCategoriesRouter("/electronics")
        } else if (route === "Health & Beauty") {
            setCategoriesRouter("/health")
        } else if (route === "Grocery") {
            setCategoriesRouter("/grocery")
        } else if (route === "Sports & Outdoors") {
            setCategoriesRouter("/sports")
        } else if (route === "Shoes & Handbags") {
            setCategoriesRouter("/shoes-handbag")
        }
        else {
            setCategoriesRouter("/")
        }
        console.log(categoriesRouter, "cate");


    }

    const fecthProducts = async (id: string) => {
        try {
            const response = await fetch(`http://129.213.109.115:8080/products/productId/${id}`);
            const data = await response.json();
            setDetails(data);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fecthProducts(id);
        fetchPosts(dispatch, nextPage);
        setRouteUrl(category1);
        // console.log("dp", category)
    }, [category1]);


    return (
        <div>
            {details.productTitle == '' ? <div className="view-product-ctn ml-50 p-10 bg-slate-50 flex items-center justify-center"><Loader /></div> :
                <div className="view-product-ctn p-10 bg-slate-50 w-full">
                    <Header />
                    <p className="border p-2 shadow-md font-smibold w-fit">
                        <NavLink to="/">Back</NavLink>
                    </p>
                    <div className="product-ctn flex  justify-center mt-16">
                        <div className=" view-product-image  border px-5"
                            style={{ width: '50%' }}
                        >
                            <img
                                style={{ height: "25rem", objectFit: "contain" }}
                                className="my-5 w-96 lg:w-full view-prodImage " src={details.imageUrls[0]} alt="" />
                            <h1 style={{ "backgroundColor": "#637d68" }} className="text-center text-white mx-2 rounded-lg font-semibold text-2xl">{details.brandName}</h1>
                        </div>
                        <div className="text view-product-text border px-5 py-5"
                            style={{ width: '50%' }}
                        >
                            <h1 className="product-title bg-white p-3 shadow rounded text-xl font-bold">{details.productTitle}</h1>
                            {/* <p>{details.dealPostTime}</p> */}
                            <div className="flex my-2 shadow justify-between bg-white p-2 rounded">
                                <p className=" my-4 text-base">Offer Price: <span style={{ "color": "#637d68" }} className="font-bold text-xl">{details.dealPrice}</span></p>
                                <p className={`my-4 text-sm font-smeibold ${rater < 5 ? "text-orange-400" : "text-orange-500 font-semibold"}`} >Ratings: {details.ratings}</p>
                            </div>

                            {/* div to show discount % */}
                            <div className="flex shadow justify-between my-2 bg-white p-2 rounded">
                                <p className="my-6">M.R.P : <span className="line-through">{details.originalPrice}</span></p>
                                <p className="my-4 text-sm font-smeibold">Discount %: <span className="font-semibold text-lg text-red-600">{Math.round(discount)}</span></p>
                            </div>
                            <div className="my-6 w-full shadow bg-white rounded">
                                <NavLink to={categoriesRouter}>
                                    <div className="flex-col lg:flex sm:flex-col overflow-x-auto items-start justify-start text-left font-semibold text-[13px]">
                                        <p style={{ border: "1px solid #637d68" }} className=" p-1 rounded-xl m-1">{category1}</p>
                                        <p style={{ border: "1px solid #637d68" }} className=" p-1 rounded-xl m-1">{category2}</p>
                                        <p style={{ border: "1px solid #637d68" }} className=" p-1 rounded-xl m-1">{category3}</p>
                                        <p style={{ border: "1px solid #637d68" }} className=" p-1 rounded-xl m-1">{category4}</p>
                                    </div>
                                </NavLink>
                            </div>
                            <button style={{ "backgroundColor": "#637d68" }} className='my-3 shadow-lg p-1.5 rounded text-white text-right'><a href={details.productUrl} target="_blank">Get Offer</a></button>
                            <div className="share flex">
                                <TwitterShareButton className='mx-2' url={details.productUrl} title={details.productTitle} hashtags={["eCommerce,amazon"]} >
                                    <TwitterIcon size={25} round />
                                </TwitterShareButton>
                                <FacebookShareButton url={details.productUrl} quote={details.productTitle}>
                                    <FacebookIcon size={25} round />
                                </FacebookShareButton>
                                <WhatsappShareButton className='mx-2' url={details.productUrl} title={details.productTitle}>
                                    <WhatsappIcon size={25} round />
                                </WhatsappShareButton>
                            </div>
                        </div>
                    </div>

                    <div className="help1 border mt-8 p-6">
                        <p>Buy <span className="font-bold">{details.productTitle} </span>online at <span className="font-bold">{details.dealPrice}</span> with discount of <span className="text-red-600">{Math.round(discount)}%</span></p>
                    </div>
                    <div className="help2 p-6 border">
                        <h1 className="font-bold text-lg">How to get this offer Online ?</h1>
                        {/* unodered list  */}
                        <ul className="list-disc my-3 ml-4">
                            <li className="py-1"> <a style={{ "color": "#637d68" }} className="font-bold underline" href={details.productUrl}>Click here</a> to go to the offer page</li>
                            <li className="py-1">Add the product to your cart</li>
                            <li className="py-2">Update Your shipping adress</li>
                            <li className="py-1">Payment</li>
                            <li className="py-1">Confirm your order</li>
                        </ul>
                    </div>

                    <div className="relatedProducts my-5">
                        <h1 className="font-bold text-lg">Related Products</h1>
                        <div id='ctn' className='ctn flex justify-center items-center'>
                            {selector.posts.filter(post => post.categories.includes(category1 || category2 || category3 || category4)).map(post => (
                                // <NavLink to={'/view_product/' + post.productURLId} className='cards px-3 mx-1  '>
                                <div className='cards px-3 mx-1' >
                                    <div className=' flex justify-center items-center' >
                                        <div className="flex flex-col items-center justify-center">
                                            <div className="bg-white img-ctn p-3 flex flex-col justify-center">
                                                <div className="off mb-1">
                                                    <p className='text-sm font-bold tred text-red-500'>{Math.round((parseInt(post.dealPrice.toString().replace(/\D/g, '')) - parseInt(post.originalPrice.toString().replace(/\D/g, ''))) / parseInt(post.originalPrice.toString().replace(/\D/g, '')) * 100)}% off</p>
                                                </div>
                                                <img className='hover:shadow-2xl mx-2 imgs' src={post.imageUrls} alt="" />
                                            </div>
                                            <div className='cards-text uppercase text-center'>
                                                <h1 className='text-center font-semibold my-1 tracking-wider  rounded my-1 text-md font-base'>{post.brandName}</h1>
                                                <a title={post.productTitle} className='productTitle  text-sm my-2'>{post.productTitle.slice(0, 40)}...</a>
                                                {/* <input type="text" className='productTitle  text-sm my-2' value={post.productTitle} /> */}
                                                <div className="flex  justify-between my-1">
                                                    {/* <p className='text-xs  text-gray-500'> <FontAwesomeIcon icon={watch1} className="text-xs mr-1" />{Math.round((Number(post.dealPostTime) / (1000 * 60 * 60)) % 24)} hours ago</p> */}
                                                    <p className='text-base lt line-through pb-1'>{post.originalPrice}</p>
                                                    <p className='font-bold text-sm' style={{ "color": "#637d68" }}>{post.dealPrice}</p>
                                                </div>
                                                <div className="flex justify-between my-1">
                                                    {/* <p className='text-xs font-bold  text-red-500'>{Math.round((parseInt(post.dealPrice.toString().replace(/\D/g, '')) - parseInt(post.originalPrice.toString().replace(/\D/g, ''))) / parseInt(post.originalPrice.toString().replace(/\D/g, '')) * 100)}% off</p> */}
                                                    {/* <p className='text-base line-through pb-1'>{post.originalPrice}</p> */}
                                                </div>
                                            </div>
                                            <div className='w-full'>

                                                <button style={{ "backgroundColor": "#7f9481" }} className='cards-btn w-full  shadow p-1 rounded text-white '><a href={post.productUrl} target="_blank">Get Offer</a></button>
                                            </div>
                                            {/* 
                                         <div className="share flex my-2">
                                                      <TwitterShareButton className='mx-1' url={post.productUrl} title={post.productTitle} hashtags={["eCommerce,amazon"]} >
                                                          <TwitterIcon size={20} round />
                                                      </TwitterShareButton>
                                                      <FacebookShareButton url={post.productUrl} quote={post.productTitle}>
                                                          <FacebookIcon size={20} round />
                                                      </FacebookShareButton>
                                                      <WhatsappShareButton className='mx-1' url={post.productUrl} title={post.productTitle}>
                                                          <WhatsappIcon size={20} round />
                                                      </WhatsappShareButton>
                                                  </div> 
                                        */}

                                        </div>
                                    </div>
                                </div>


                            ))}
                        </div>
                        {/* loop through category and filter out products that includes each of the category */}
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
}
export default ViewProduct;