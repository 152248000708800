import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { fetchPosts } from '../../statesManagements/actions/postActions'
import { fetchBrands } from '../../statesManagements/actions/brandsActions';
import UsePagination from "../toolkits/usePagination";
// fontawesome
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faShirt } from "@fortawesome/free-solid-svg-icons";
// import { faSprayCanSparkles } from '@fortawesome/free-solid-svg-icons';
// import { faPlateWheat } from "@fortawesome/free-solid-svg-icons";
// import { faTv } from "@fortawesome/free-solid-svg-icons";
// import { faShoePrints } from "@fortawesome/free-solid-svg-icons";
// import { faHeadphones } from "@fortawesome/free-solid-svg-icons";
// import { faStopwatch20 } from "@fortawesome/free-solid-svg-icons";
// import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
// import { faBagShopping } from '@fortawesome/free-solid-svg-icons';
// import { faComputer } from '@fortawesome/free-solid-svg-icons';
// import { faHospital } from '@fortawesome/free-solid-svg-icons';
// import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
// import { faHamburger } from '@fortawesome/free-solid-svg-icons';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
// import { IconProp } from "@fortawesome/fontawesome-svg-core";

//images
import brands1 from "../../../src/assets/brandsImg/boat.jpg"
import brands2 from "../../../src/assets/brandsImg/2153869-removebg-preview.png"
import brands3 from "../../../src/assets/brandsImg/apple-logo-brand-iphone-8-png-favpng-kpjuE97YAJfvw2kincmpHbXii-removebg-preview.png"
import brands4 from "../../../src/assets/brandsImg/Color-Pampers-Logo-removebg-preview.png"
import brands5 from "../../../src/assets/brandsImg/dove.webp"
import brands6 from "../../../src/assets/brandsImg/images-removebg-preview (4).png"
import brands7 from "../../../src/assets/brandsImg/images-removebg-preview (5).png"
import brands8 from "../../../src/assets/brandsImg/images-removebg-preview (6).png"
import brands9 from "../../../src/assets/brandsImg/images-removebg-preview (7).png"
import brands10 from "../../../src/assets/brandsImg/images-removebg-preview (8).png"
import tosbpLogo from "../../assets/tosbpLogo.png"
//react share
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    FacebookIcon,
    WhatsappIcon,
    TwitterIcon,
} from "react-share";



import { NavLink, useNavigate } from "react-router-dom";
import smartPhones from "../../assets/drive/smartphones.jpg";
import smartWatch from "../../assets/drive/smartwatches.jpg";
import speakers from "../../assets/drive/speakers.jpg";
import usbCables from "../../assets/drive/usb-cables.jpg";
import headphones from "../../assets/drive/over-ear.jpg";
import earPhones from "../../assets/drive/in-ear.jpg";

// BLA
import television from "../../assets/drive/televisions.jpg";
import washingMachines from "../../assets/drive/washing-machines-dryers.jpg";
import refrigerators from "../../assets/drive/refrigerators.jpg";
import airCoolers from "../../assets/drive/air-coolers.jpg";
import microwaves from "../../assets/drive/oven-toaster-grills.jpg";
import digitalCamera from "../../assets/drive/digital-camera.jpg";


// BABP

import vedaka from "../../assets/drive/vedaka.jpg";
import solimo from "../../assets/drive/solimo.jpg";

// BELPBB

import oneplus from "../../assets/drive/oneplus.jpg";
import boat from "../../assets/drive/boat.jpg";
import samsung from "../../assets/drive/samsung.jpg";

// BBPBB

import lakme from "../../assets/drive/lakme.jpg";
import maybelline from "../../assets/drive/maybelline.jpg";
import loreal from "../../assets/drive/loreal.jpg";




import Loader from '../toolkits/loader';


type Props = {
    inputValue: string
}
const Body: React.FC<Props> = ({ inputValue }) => {
    // const shirt = faShirt as IconProp;
    // const face = faSprayCanSparkles as IconProp;
    // const wheat = faPlateWheat as IconProp;
    // const tv = faTv as IconProp;
    // const shoe = faShoePrints as IconProp;
    // const headphone = faHeadphones as IconProp;
    // const watch = faStopwatch20 as IconProp;
    // const bag = faBagShopping as IconProp;
    // const computer = faComputer as IconProp;
    // const hospital = faHospital as IconProp;
    // const watch1 = faStopwatch as IconProp;
    // const hamburger = faHamburger as IconProp;
    // const search = faSearch as IconProp;
    // const arrowRight = faArrowRight as IconProp;
    // const arrowLeft = faArrowLeft as IconProp;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selector = useSelector((state: any) => state.posts);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(25);
    const [nextPage, setNextPage] = useState(1);
    const [searchValue, setSearchValue] = useState('123');

    useEffect(() => {
        fetchPosts(dispatch, nextPage);
        // fetchBrands(dispatch)
        // console.log("dp", dealPrice)
    }
        , [])


    const handlePageChange = () => {

        // setNextPage(nextPage + 1);
        fetchPosts(dispatch, nextPage + 1);
        window.scrollTo({
            top: 240,
            behavior: 'smooth',
        });

    }
    const handlePageChange2 = () => {

        fetchPosts(dispatch, nextPage - 1);
        window.scrollTo({
            top: 240,
            behavior: 'smooth',
        });
    }
    // handleChange
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value.toLowerCase());
        fetchPosts(dispatch, nextPage);
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selector.posts.slice(indexOfFirstItem, indexOfLastItem);
    //slice first 20 trings

    const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

    const BEP = [
        {
            name: "Smart Phone ",
            img: smartPhones,
            link: "smartphones"
        },
        {
            name: "Smart Watch",
            img: smartWatch,
            link: "smartwatches"
        },
        {
            name: "Bluetooth Speaker",
            img: speakers,
            link: "speakers"
        },
        {
            name: "USB Cables",
            img: usbCables,
            link: "usb-cables"
        },
        {
            name: "Headphones",
            img: headphones,
            link: "over-ear"
        },
        {
            name: "Earphones (In-Ear)",
            img: earPhones,
            link: "in-ear"
        }
    ]

    const BLA = [
        {
            name: "Television",
            img: television,
            link: "televisions"
        },
        {
            name: "Washing Machines",
            img: washingMachines,
            link: "washing-machines-dryers"
        },
        {
            name: "Refrigerators",
            img: refrigerators,
            link: "refrigerators"
        },
        {
            name: "Air Coolers",
            img: airCoolers,
            link: "air-coolers"
        },
        {
            name: "Microwaves",
            img: microwaves,
            link: "oven-toaster-grills"
        },
        {
            name: "Digital Camera",
            img: digitalCamera,
            link: "digital-camera"
        }
    ]

    const BABP = [
        {
            name: "Vedaka",
            img: vedaka,
            link: "vedaka"
        },
        {
            name: "Solimo",
            img: solimo,
            link: "solimo"
        }
    ]

    const BELPBB = [
        {
            name: "OnePlus",
            img: oneplus,
            link: "oneplus"
        },
        {
            name: "Boat",
            img: boat,
            link: "boat"
        },
        {
            name: "Samsung",
            img: samsung,
            link: "samsung"
        }
    ]

    const BBPBB = [
        {
            name: "Lakme",
            img: lakme,
            link: "lakme"
        },
        {
            name: "Maybelline",
            img: maybelline,
            link: "maybelline"
        },
        {
            name: "Loreal",
            img: loreal,
            link: "loreal"
        }
    ]


    return (
        <div>

            {/* <h1 className='text-xl  store font-bold pl-16'>Top Selling 🔥</h1> */}
            <div id='' className='flex flex-col items-center'>

                {/* if selector is empty */}
                {/* {selector.posts.length === 0 ? <div><Loader /></div> :
                    <div id='ctn' className='ctn flex justify-center items-center'>
                        {currentItems.map((post: any, index: any) => {
                            return (
                                <div className='cards px-3 mx-1  '>
                                    <div className=' flex flex-col justify-center items-center' >
                                        <NavLink to={'/view_product/' + post.productURLId} key={index.productId} className="flex flex-col items-center justify-center">

                                            <div className="bg-white img-ctn p-3 flex flex-col justify-center">
                                                <div className="off mb-1">
                                                    <p className='text-sm font-bold tred text-red-500'>{Math.round((parseInt(post.dealPrice.toString().replace(/\D/g, '')) - parseInt(post.originalPrice.toString().replace(/\D/g, ''))) / parseInt(post.originalPrice.toString().replace(/\D/g, '')) * 100)}% off</p>
                                                </div>
                                                <img className='hover:shadow-2xl mx-2 imgs' src={post.imageUrls} alt="" />
                                            </div>
                                            <div className='cards-text uppercase text-center'>
                                                <h1 className='text-center font-semibold my-1 tracking-wider  rounded my-1 text-md font-base'>{post.brandName}</h1>
                                                <a title={post.productTitle} className='productTitle  text-sm my-2'>{post.productTitle.slice(0, 45)}...</a>
                                                
                                                <div className="flex  justify-between my-1">
                                                  
                                                    <p className='text-base lt line-through pb-1'>{post.originalPrice}</p>
                                                    <p className='font-bold text-sm' style={{ "color": "#637d68" }}>{post.dealPrice}</p>
                                                </div>
                                              
                                            </div>

                                        </NavLink>
                                        <div className='w-full'>

                                            <button style={{ "backgroundColor": "#7f9481" }} className='cards-btn w-full  shadow p-1 rounded text-white '><a href={post.productUrl} target="_blank">Get Offer</a></button>
                                        </div>
                                    </div>
                                </div>
                            )

                        }
                        )}


                    </div>
                } */}
                {/* <div className=" pagination p-4 flex items-center justify-center">
                    <button className={`page-btn text-white mx-5 py-1 w-20 text-lg' ${nextPage < 1 ? "paginate-btn-fade mx-5 py-1 px-5 text-lg" : "paginate-btn mx-5 py-1 px-5 text-lg"}`} onClick={handlePageChange2} disabled={nextPage < 1}>
                        <FontAwesomeIcon icon={arrowLeft} className="text-lg" />
                    </button>
                    < UsePagination itemsPerPage={itemsPerPage} totalItems={selector.posts.length} paginate={paginate} />

                    <button style={{ "backgroundColor": "#637d68" }} className='page-btn  text-white mx-5  py-1 w-20 text-lg' onClick={handlePageChange} >
                        <FontAwesomeIcon icon={arrowRight} className="text-lg" />
                    </button>
                </div> */}

                <div className="categories p-5">
                    <div className="my-4">
                        <h1 className="font-semibold text-white text-lg bg-black bg-opacity-40 p-2 rounded-lg w-fit">Best Electronic Products</h1>

                        <div  className="flex flex-wrap justify-evenly ">
                            {
                                BEP.map((item: any, index: number) => {
                                    return (
                                        <div data-aos="flip-left" key={index}>
                                            <div
                                                onClick={
                                                    () => {
                                                        navigate(`product-display/${item?.link}`)
                                                    }
                                                }
                                                style={{
                                                    "backgroundColor": "#637d68",
                                                    backgroundImage: `url(${item?.img})`,
                                                }}
                                                className="flex items-end justify-start p-1 m-2 w-80 align-baseline self-baseline h-48 bg-cover bg-center bg-no-repeat rounded shadow-md cursor-pointer hover:shadow-lg">
                                                {/* title with transparent bg */}
                                                <h1
                                                    className="font-bold text-white text-lg bg-black bg-opacity-50 p-2 rounded-lg"
                                                >{item?.title}</h1>

                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>


                    <div className="my-10">
                        <h1 className="font-semibold text-white text-lg bg-black bg-opacity-40 p-2 rounded-lg w-fit">Best Large Appliances</h1>

                        <div className="flex flex-wrap justify-evenly ">
                            {
                                BLA.map((item: any, index: number) => {
                                    return (
                                        <div data-aos="fade-down" key={index}>
                                            <div
                                                onClick={
                                                    () => {
                                                        navigate(`product-display/${item?.link}`)
                                                    }
                                                }
                                                style={{
                                                    "backgroundColor": "#637d68",
                                                    backgroundImage: `url(${item?.img})`,
                                                }}
                                                className="flex items-end justify-start p-1 m-2 w-80 align-baseline self-baseline h-48 bg-cover bg-center bg-no-repeat rounded shadow-md cursor-pointer hover:shadow-lg">
                                                {/* title with transparent bg */}
                                                <h1
                                                    className="font-bold text-white text-lg bg-black bg-opacity-50 p-2 rounded-lg"
                                                >{item?.title}</h1>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>

                        {/* <div className='p-4 flex flex-row flex-wrap justify-evenly box-border items-center h-fit'>
                        <NavLink to="/fashion">
                            <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                                <FontAwesomeIcon className='my-4  px-10 text-blue-900 text-6xl' icon={shirt} />
                                <hr />
                                <h1 className='font-bold text-lg mt-2 px-10'>FASHION</h1>
                            </div>
                        </NavLink>
                        <NavLink to="/beauty">
                            <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                                <FontAwesomeIcon className='my-4 px-10 text-pink-600 text-6xl' icon={face} />
                                <hr />
                                <h1 className='font-bold text-md mt-2 px-1'>Beauty & Personal Care</h1>
                            </div>
                        </NavLink>

                        <NavLink to="/mobile">
                            <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                                <FontAwesomeIcon className='my-4 px-10 text-blue-600 text-6xl' icon={wheat} />
                                <hr />
                                <h1 className='font-bold text-lg mt-2 px-10'>Home & Kitchen</h1>
                            </div>
                        </NavLink>

                        <NavLink to="/electronics">
                            <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                                <FontAwesomeIcon className='my-4 px-10 text-orange-600 text-6xl' icon={tv} />
                                <hr />
                                <h1 className='font-bold text-lg mt-2 px-10'>Electronics</h1>
                            </div>
                        </NavLink>

                        <NavLink to="/shoes-handbags">
                            <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                                <FontAwesomeIcon className='my-4 px-10 text-slate-600 text-6xl' icon={shoe} />
                                <hr />
                                <h1 className='font-bold text-lg mt-2 px-10'>FootWare</h1>
                            </div>
                        </NavLink>

                        <NavLink to="/computer">
                            <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                                <FontAwesomeIcon className='my-4 px-10 text-amber-600 text-6xl' icon={headphone} />
                                <hr />
                                <h1 className='font-bold text-lg mt-2 px-10'>Mobile Accessories</h1>
                            </div>
                        </NavLink>

                        <NavLink to="">
                            <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                                <FontAwesomeIcon className='my-4 px-10 text-teal-600 text-6xl' icon={watch} />
                                <hr />
                                <h1 className='font-bold text-lg mt-2 px-10'>Accessories</h1>
                            </div>
                        </NavLink>

                        <NavLink to="/shoes-handbags">
                            <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                                <FontAwesomeIcon className='my-4 px-10 text-emerald-600 text-6xl' icon={bag} />
                                <hr />
                                <h1 className='font-bold text-lg mt-2 px-10'>Bags</h1>
                            </div>
                        </NavLink>

                        <NavLink to="/computer">
                            <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                                <FontAwesomeIcon className='my-4 px-10 text-bluegray-600 text-6xl' icon={computer} />
                                <hr />
                                <h1 className='font-bold text-lg mt-2 px-10'>Computer Accessories</h1>
                            </div>
                        </NavLink>

                        <NavLink to="/health">
                            <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                                <FontAwesomeIcon className='my-4 px-10 text-red-600 text-6xl' icon={hospital} />
                                <hr />
                                <h1 className='font-bold text-lg mt-2 px-10'>Health & Wellness</h1>
                            </div>
                        </NavLink>

                    </div> */}
                    </div>

                    <div className="my-10">
                        <h1 className="font-semibold text-white text-lg bg-black bg-opacity-40 p-2 rounded-lg w-fit">Best Amazon Brand Products</h1>

                        <div className='p-4 flex flex-row flex-wrap justify-evenly box-border items-start h-fit'>
                            {
                                BABP.map((item: any, index: any) => {
                                    return (
                                        <div data-aos="zoom-in-down" key={index}>
                                            <div
                                                onClick={
                                                    () => {
                                                        navigate(`product-display/${item?.link}`)
                                                    }
                                                }
                                                style={{
                                                    "backgroundColor": "#637d68",
                                                    backgroundImage: `url(${item?.img})`,
                                                }}
                                                className="flex items-end justify-start p-1 m-2 w-80 align-baseline self-baseline h-48 bg-cover bg-center bg-no-repeat rounded shadow-md cursor-pointer hover:shadow-lg">
                                                {/* title with transparent bg */}
                                                <h1
                                                    className="font-bold text-white text-lg bg-black bg-opacity-50 p-2 rounded-lg"
                                                >{item?.title}</h1>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>

                    </div>


                    <div className="my-10">
                        <h1 className="font-semibold text-white text-lg bg-black bg-opacity-40 p-2 rounded-lg w-fit">Best Electroics Products by Brands</h1>

                        <div className='p-4 flex flex-row flex-wrap justify-evenly box-border items-start h-fit'>
                            {
                                BELPBB.map((item: any, index: any) => {
                                    return (
                                        <div data-aos="zoom-in-down" key={index}>
                                            <div
                                                onClick={
                                                    () => {
                                                        navigate(`product-display/${item?.link}`)
                                                    }
                                                }
                                                style={{
                                                    "backgroundColor": "#637d68",
                                                    backgroundImage: `url(${item?.img})`,
                                                }}
                                                className="flex items-end justify-start p-1 m-2 w-80 align-baseline self-baseline h-48 bg-cover bg-center bg-no-repeat rounded shadow-md cursor-pointer hover:shadow-lg">
                                                {/* title with transparent bg */}
                                                <h1
                                                    className="font-bold text-white text-lg bg-black bg-opacity-50 p-2 rounded-lg"
                                                >{item?.title}</h1>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>

                    <div className="my-10">
                        <h1 className="font-semibold text-white text-lg bg-black bg-opacity-40 p-2 rounded-lg w-fit">Best Beauty products by Brand</h1>

                        <div className='p-4 flex flex-row flex-wrap justify-evenly box-border items-start h-fit'>
                            {
                                BBPBB.map((item: any, index: any) => {
                                    return (
                                        <div data-aos="zoom-in-down" key={index}>
                                            <div
                                                onClick={
                                                    () => {
                                                        navigate(`product-display/${item?.link}`)
                                                    }
                                                }
                                                style={{
                                                    "backgroundColor": "#637d68",
                                                    backgroundImage: `url(${item?.img})`,
                                                }}
                                                className="flex items-end justify-start p-1 m-2 w-80 align-baseline self-baseline h-48 bg-cover bg-center bg-no-repeat rounded shadow-md cursor-pointer hover:shadow-lg">
                                                {/* title with transparent bg */}
                                                <h1
                                                    className="font-bold text-white text-lg bg-black bg-opacity-50 p-2 rounded-lg"
                                                >{item?.title}</h1>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>

                </div>




                {/* <div className="brands p-5">
                    <h1 className='font-bold text-xl pl-10'>Brands</h1>
                    <div className='p-4 flex flex-row flex-wrap justify-evenly box-border items-center h-fit'>
                        <div className='brand-ctn flex my-5 items-center  w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <img className="w-32 h-20" src={brands1} alt="" />
                            <hr />
                            <h1 className=' font-bold text-lg mt-2 px-10'>Boat</h1>
                        </div>
                        <div className='flex brand-ctn items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <img className="w-32 h-20" src={brands2} alt="" />
                            <hr />
                            <h1 className='font-bold text-md mt-2 px-1'>Fastrack</h1>
                        </div>
                        <div className='flex brand-ctn items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <img className="w-32 h-20" src={brands3} alt="" />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Apple</h1>
                        </div>
                        <div className='flex brand-ctn items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <img className="w-32 h-20" src={brands4} alt="" />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Pampers</h1>
                        </div>
                        <div className='flex brand-ctn items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <img className="w-32 h-20" src={brands5} alt="" />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Dove</h1>
                        </div>
                        <div className='flex brand-ctn items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <img className="w-32 h-20" src={brands6} alt="" />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Lakme</h1>
                        </div>

                        <div className='flex brand-ctn items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <img className="w-32 h-20" src={brands7} alt="" />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Puma</h1>
                        </div>
                        <div className='flex brand-ctn items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <img className="w-32 h-20" src={brands8} alt="" />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Sketchers</h1>
                        </div>
                        <div className='flex brand-ctn items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <img className="w-32 h-20" src={brands9} alt="" />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Reebok</h1>
                        </div>
                        <div className='flex brand-ctn items-center my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <img className="w-32 h-20" src={brands10} alt="" />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Beta</h1>
                        </div>
                        <button style={{ "backgroundColor": "#637d68" }} className=' m-4 shadow-lg rounded text-white p-3 text-2xl'>
                            <NavLink to="brands">
                                View More
                            </NavLink>
                        </button>

                    </div>
                </div> */}
            </div>
        </div>
    )
}
export default Body;
