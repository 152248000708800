import React from "react";

type Props = {}

    const Loader: React.FC<Props> = () => {
        return (
            <div className="flex spin justify-center items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        )
        }
    export default Loader;