import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "../homepage/footer";
import Header from "../homepage/Header";

type Props = {};

const Disclaimer: React.FC<Props> = () => {
    return (
        <div className="">
            <Header />
            <div className="">
            </div>
            <div className="mt-10 p-5 text-center">
                <h1 className="font-bold text-xl lg:text-3xl">🚨 Important Disclaimer 🚨</h1>
            </div>
            <div className="p-5 my-4">
                <p className="text-justify px-10 font-semibold text-xl">
                    <span className="mx-1 text-emerald-400 text-2xl">
                        <NavLink to="/">
                            impoffers.com
                        </NavLink>
                    </span> is a  participant in the Amazon Services LLC Associates Program, an affiliate advertising program de signed to provide a means for us to earn fees by linking to
                    <span className="underline mx-1">
                        <NavLink to="http://amazon.in/">Amazon.in</NavLink>
                    </span>
                    and affiliated sites.
                    Amazon and the Amazon logo are trademarks of <span className="underline mx-1">
                        <NavLink to="http://amazon.com/">Amazon.com</NavLink>
                    </span>, Inc. or its affiliates. <br />
                    Price is current at time/date stamp of the Post.
                    Product prices and availability are accurate as of the
                    date/time indicated and are subject to change.
                    Any price and availability information displayed on
                    [relevant Amazon Site(s), as applicable] at the time of
                    purchase will apply to the purchase of this product.
                    Promo Codes or Coupons can expire at any time.
                    While Supplies Last!
                    Photos of products used on this page belong to the seller, I am not the seller and I do not own these photos.
                </p>
            </div>
            <Footer />
        </div>

    );
};

export default Disclaimer;