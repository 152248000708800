import React, { useEffect, useState } from "react";
import { useDispatch, } from 'react-redux'
import { searchPosts } from '../../statesManagements//actions/postActions'
// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { NavLink } from "react-router-dom";
import Body from "./Body";
type Props = {}
const Header: React.FC<Props> = () => {
    const search = faSearch as IconProp;
    const refresh = faRefresh as IconProp;
    const shoppingCart = faShoppingCart as IconProp;
    const fire = faFire as IconProp;
    const filter = faFilter as IconProp;
    const dispatch = useDispatch();
    const [nextPage, setNextPage] = useState(0);
    const [query, setQuery] = useState("")

    
   
    useEffect(() => {
        searchPosts(dispatch, nextPage, query);
    }, []);

    //function to handle search input
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setQuery(e.target.value.toLowerCase())
        searchPosts(dispatch, nextPage, query);
        // console.log(query)
    }
    //function to refresh searchPosts
    const handleRefresh = () => {
        //    refresh the page
        window.location.reload();
    }
    //function to redirect to search route
    const handleSearchRoute = () => {
        window.location.href = "/search";
        //set the query to local storage
        localStorage.setItem('query', JSON.stringify(query));
        // get query from local storage and set tp query
        const items = JSON.parse(localStorage.getItem('query') as string);
        setQuery(items);
    }
    return (
        <div className="header1">

            <div className="headText text-center flex flex-col items-center mb-0 my-10 m-2 p-2">
                <div className="fixed top-4 bg-slate-100 shadow-lg w-fit p-3 rounded-2xl">

                    <input
                        className="px-2 bg-slate-100 focus:outline-none  "
                        type="text"
                        placeholder="Search..."
                        onChange={handleSearch}
                        value={query}
                    />
                    <span onClick={handleSearchRoute} className=" rounded-l-2xl pl-72  text-center"><FontAwesomeIcon icon={search} /></span>
                </div>
                <h1 className="headerH1 tracking-widest  text-5xl my-10">The Online Shopping At Best Price</h1>
            </div>
            <div className="header1 cursor-pointer py-2 my-6 m-6 p-4 ">

                {/* <div className="data-channel">
                <Body inputValue={query} />
            </div> */}
                <header className="">


                    <div className=" mini-nav hidden lg:flex justify-evenly items-center">
                        <p style={{ "color": "#637d68" }} onClick={handleRefresh} className=" tracking-widest border-l-2 border-r-2 border-black text-xl font-semibold  h-fit  px-6 mx-3  text-center">
                            <span className="px-1"><FontAwesomeIcon icon={refresh} /></span>
                            Latest
                        </p>
                        <p className="text-slate-600 h-fit  px-6 mx-3 tracking-widest border-l-2 border-r-2 border-black text-xl  text-center">
                            <span className="px-1"><FontAwesomeIcon icon={shoppingCart} /></span>
                            <NavLink to="/brands"
                            // className={(isActive: Boolean) =>
                            //     "nav-link" + (isActive ? "active" : "bg-red")
                            // }
                            >
                                Store
                            </NavLink>

                        </p>
                        <p className="text-black h-fit  px-6 mx-3 tracking-widest border-l-2 border-r-2 border-black text-xl font-semibold text-center">
                            <span className="px-1"><FontAwesomeIcon icon={fire} /></span>
                            <NavLink to="/hot">
                            Hot
                            </NavLink>
                        </p>
                        <p className="text-emerald-400 h-fit  px-6 mx-3 tracking-widest border-l-2 border-r-2 border-black text-xl font-semibold text-center">
                            <span className="px-1"><FontAwesomeIcon icon={filter} /></span>
                            <NavLink to="/categories">
                                Categories
                            </NavLink>
                        </p>

                    </div>
                </header>
            </div>
        </div>
    );
}


export default Header;