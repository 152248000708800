import React from "react";
import Homepage from "../views/homepage/Homepage";
import Categories from "../views/pages/categories";
import Brands from "../views/pages/brands";
import Loader from "../views/toolkits/loader";
import Beauty from "../views/categories/beauty";
import Search from "../views/pages/search";
import ViewProduct from "../views/pages/viewProduct";
import Mobile from "../views/pages/mobile";
import Fashion from "../views/pages/fashion";
import Electronics from "../views/categories/electronics";
import Health from "../views/categories/health";
import Computer from "../views/categories/compiter"
import ShoesBags from "../views/categories/sh"
import Grocery from "../views/categories/grocery";
import Disclaimer from "../views/pages/discliamer";
import Hot from "../views/pages/hot";
import GiftIdeas from "../views/pages/giftIdeas";
import ProductDisplay from "../views/pages/ProductsDisplay";
// import Pagination from "../views/toolkits/pagination";

let PublicRoutes = [
    {
        path: "/",
        name: "homepage",
        privateroute: false,
        component: Homepage,
        show: false,
        id: "homepage",
    },
    {
        path: "/categories",
        name: "categories",
        privateroute: false,
        component: Categories,
        show: false,
        id: "categories",
    },
    {
        path: "/brands",
        name: "brands",
        privateroute: false,
        component: Brands,
        show: false,
        id: "brands",

    },
    {
        path: "/loader",
        name: "loader",
        privateroute: false,
        component: Loader,
        show: false,
        id: "loader",
    },
    {
        path: "/beauty",
        name: "beauty",
        privateroute: false,
        component: Beauty,
        show: false,
        id: "beauty",
    },
    {
        path: "/search",
        name: "search",
        privateroute: false,
        component: Search,
        show: false,
        id: "search",
    },
    {
        path: "/view_product/:id",
        name: "viewProduct",
        privateroute: false,
        component: ViewProduct,
        show: false,
        id: "viewProduct",
    },
    {
        path: "/mobile",
        name: "mobile",
        privateroute: false,
        component: Mobile,
        show: false,
        id: "mobile",
    },
    {
        path: "/fashion",
        name: "fashion",
        privateroute: false,
        component: Fashion,
        show: false,
        id: "fashion",
    },
    {
        path: "/electronics",
        name: "electronics",
        privateroute: false,
        component: Electronics,
        show: false,
        id: "electronics",
    },
    {
        path: "/health",
        name: "health",
        privateroute: false,
        component: Health,
        show: false,
        id: "health",
    },
    {
        path: "/computer",
        name: "computer",
        privateroute: false,
        component: Computer,
        show: false,
        id: "computer",
    },
    {
        path: "/shoes-handbag",
        name: "shoes-handbag",
        privateroute: false,
        component: ShoesBags,
        show: false,
        id: "shoes-handbag",
    },
    {
        path: "/grocery",
        name: "grocery",
        privateroute: false,
        component: Grocery,
        show: false,
        id: "grocery",
    },
    {
        path: "/disclaimer",
        name: "disclaimer",
        privateroute: false,
        component: Disclaimer,
        show: false,
        id: "disclaimer",
    },
    {
        path: "/hot",
        name: "hot",
        privateroute: false,
        component: Hot,
        show: false,
        id: "hot",
    },
    {
        path: "/gift-ideas/:title",
        name: "gift-ideas",
        privateroute: false,
        component: GiftIdeas,
        show: false,
        id: "gift-ideas",
    },
    {
        path: "/product-display/:title",	
        name: "product-display",
        privateroute: false,
        component: ProductDisplay,
        show: false,
        id: "product-display",
    }


];


export default PublicRoutes;