import { FETCH_CATEGORIES } from "../actions/types";
import * as types from "../actionTypes"
const initialState = {

    categories: [],
    loading: false,
    err: null,

    giftIdeas: [],
    giftIdeasLoading: false,
    giftIdeasErr: null,

    dynamicProducts: [],
    dynamicProductsLoading: false,
    dynamicProductsErr: null


}
export type ActionResponse = {
    type: string;
    payload: any;
  };
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default (state = initialState, { type, payload }: ActionResponse) => {
    switch (type) {
        case types.FETCH_CATEGORIES.REQUEST:
            return {
                ...state,
                loading: true
            };

        case types.FETCH_GIFT_IDEAS.REQUEST:
            return {
                ...state,
                giftIdeasLoading: true,
                giftIdeas: [],
                giftIdeasErr: null
            };
        
        case types.FETCH_DYNAMICPRODUCTS.REQUEST:
            return {
                ...state,
                dynamicProductsLoading: true,
                dynamicProducts: [],
                dynamicProductsErr: null
            }

        case types.FETCH_GIFT_IDEAS.SUCCESS:
            return {
                ...state,
                giftIdeas: payload,
                giftIdeasLoading: false
            };
        case types.FETCH_CATEGORIES.SUCCESS:
            return {
                ...state,
                categories: payload,
                loading: false
            };

        case types.FETCH_DYNAMICPRODUCTS.SUCCESS:
            return {
                ...state,
                dynamicProducts: payload,
                dynamicProductsLoading: false,
                dynamicProductsErr: null
            }

        case types.FETCH_GIFT_IDEAS.FAILURE:
            return {
                ...state,
                giftIdeasErr: payload,
                giftIdeasLoading: false
            };
        case types.FETCH_CATEGORIES.FAILURE:
            return {
                ...state,
                err: payload,
                loading: false
            };

        case types.FETCH_DYNAMICPRODUCTS.FAILURE:
            return {
                ...state,
                dynamicProductsErr: payload,
                dynamicProductsLoading: false,
            }
        default:
            return state;
    }
}
// Compare this snippet from src\actions\categoriesActions.js:
