import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import quality from "../../assets/quality.png";
import energy from "../../assets/energy.png";
import fashion from "../../assets/categories/fashion-removebg-preview.png";
import pods from "../../assets/categories/pods-removebg-preview.png";
import holi from "../../assets/drive/holi.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHamburger } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import tosbpLogo from "../../assets/tosbpLogo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { localDrive } from "../toolkits/localDrive";
import papps from "../../assets/drive/gifts-for-pappa.jpg";
import bbyShower from "../../assets/drive/bbyshower.jpg";
import bhasi from "../../assets/drive/bhasi.jpg";
import birthday from "../../assets/drive/birthday.jpg";
import diwali from "../../assets/drive/gifts-for-diwali.jpg";
import grannies from "../../assets/drive/gifts-for-grandparents.jpg";
import houseWarming from "../../assets/drive/houseWarming.jpg";
import husband from "../../assets/drive/gifts-for-husband.jpg";
import kidsgifts from "../../assets/drive/kidsgifts.jpg";
import mother from "../../assets/drive/gifts-for-mummy.jpg";
import sisters from "../../assets/drive/sisters.jpg";
import val from "../../assets/drive/gifts-for-velentines-day.jpg";
import wedding from "../../assets/drive/wedding-gifts.jpg";
import wife from "../../assets/drive/gifts-for-wife.jpg";
import anniversary from "../../assets/drive/anniversary-gifts.jpg";

import sister from "../../assets/drive/gifts-for-sister.jpg";
import brother from "../../assets/drive/gifts-for-brother.jpg";
import children from "../../assets/drive/gifts-for-children.jpg";

// import anniversary from "../../assets/drive/anniversary.jfif";
type Props = {};
const Hero: React.FC<Props> = () => {
  const hamburger = faHamburger as IconProp;
  const search = faSearch as IconProp;
  const bars = faBars as IconProp;
  const refresh = faRefresh as IconProp;
  const shoppingCart = faShoppingCart as IconProp;
  const fire = faFire as IconProp;
  const filter = faFilter as IconProp;
  const twitter = faTwitter as IconProp;
  const facebook = faFacebook as IconProp;
  const instagram = faInstagram as IconProp;
  const close = faClose as IconProp;

  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value.toLowerCase());
  };
  const handleSearchRoute = () => {
    window.location.href = "/search";
    localStorage.setItem("query", JSON.stringify(query));
    const items = JSON.parse(localStorage.getItem("query") as string);
    setQuery(items);
  };
  const handleRefresh = () => {
    window.location.reload();
  };
  const handleHamburger = () => {
    setIsOpen(!isOpen);
  };

  const [giftsIdeas, setGiftsIdeas] = useState<any>([
    {
      id: 1,
      title: "Gift for Papps",
      imgPath: papps,
    },
    {
      id: 2,
      title: "Gift for Baby Shower",
      imgPath: bbyShower,
    },
    {
      id: 3,
      title: "Gift for Bhasi",
      imgPath: bhasi,
    },
    {
      id: 4,
      title: "Gift for Birthday",
      imgPath: birthday,
    },
    {
      id: 5,
      title: "Gift for Diwali",
      imgPath: diwali,
    },
    {
      id: 6,
      title: "Gift for Grandparents",
      imgPath: grannies,
    },
    {
      id: 7,
      title: "Gift for House Warming",
      imgPath: houseWarming,
    },
    {
      id: 8,
      title: "Gift for Husband",
      imgPath: husband,
    },
    {
      id: 9,
      title: "Gift for Kids",
      imgPath: kidsgifts,
    },
    {
      id: 10,
      title: "Gift for Mother",
      imgPath: mother,
    },
    {
      id: 15,
      title: "Gift for Children",
      imgPath: children,
    },
    {
      id: 11,
      title: "Gift for Sisters",
      imgPath: sister,
    },
    {
      id: 12,
      title: "Gift for Valentine",
      imgPath: val,
    },
    {
      id: 13,
      title: "Gift for Wedding",
      imgPath: wedding,
    },
    {
      id: 14,
      title: "Gift for Wife",
      imgPath: wife,
    },
    // {
    //     id: 15,
    //     title: "Gift for Anniversary",
    //     imgPath: anniversary,
    // },
  ]);
  const selector = useSelector((state: any) => state.posts);

  // function to render different images in the array after 10s
  const [index, setIndex] = useState(0);
  const [index2, setIndex2] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => index + 1);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log("gIdeas:", giftsIdeas);
    const interval2 = setInterval(() => {
      setIndex2((index2) => index2 + 1);
    }, 3000);
    return () => clearInterval(interval2);
  }, []);
  //  get array of images from the store
  const images = selector.posts.map((item: any) => item.imageUrls);
  const image = images[index % images.length];

  const titles = selector.posts.map((item: any) => item.brandName);
  const titless = titles[index % titles.length];

  // rearrange array of images in reverse order
  const reverseImages = images.reverse();
  const image2 = reverseImages[index2 % reverseImages.length];
  const navigate = useNavigate();

  const [giftsForCelebrations, setGiftsForCelebrations] = useState<any>([
    {
      id: 1,
      title: "Father's Day",
      imgPath: papps,
      path: "gifts-for-pappa",
    },
    {
      id: 2,
      title: "Mother's Day",
      imgPath: mother,
      path: "gifts-for-mummy",
    },
    {
      id: 3,
      title: "Valentine's Day",
      imgPath: val,
      path: "gifts-for-valentines",
    },
    {
      id: 4,
      title: "Wedding Gifts",
      imgPath: wedding,
      path: "wedding-gifts",
    },
    {
      id: 5,
      title: "Anniversary Gifts",
      imgPath: anniversary,
      path: "anniversary-gifts",
    },
    {
      id: 6,
      title: "Diwali",
      imgPath: diwali,
      path: "gifts-for-diwali",
    },
  ]);

  const [giftsForLovedOnes, setGiftsForLovedOnes] = useState<any>([
    {
      id: 1,
      title: "Gift for Sister",
      imgPath: sister,
      path: "gifts-for-sister",
    },
    {
      id: 2,
      title: "Gift for Brother",
      imgPath: brother,
      path: "gifts-for-brother",
    },
    {
      id: 3,
      title: "Gift for Husband",
      imgPath: husband,
      path: "gifts-for-husband",
    },
    {
      id: 4,
      title: "Gift for Wife",
      imgPath: wife,
      path: "gifts-for-wife",
    },
    {
      id: 15,
      title: "Gift for Children",
      imgPath: children,
      path: "gifts-for-children",
    },
    {
      id: 5,
      title: "Gifts for GrandParents",
      imgPath: grannies,
      path: "gifts-for-grandparents",
    },
  ]);

  return (
    <div>
      <div className="mobile flex items-center justify-center">
        {isOpen ? (
          <div className="nav-menu-mobile flex flex-col items-center justify-center">
            <div className="close-toggle">
              <FontAwesomeIcon
                className="text-2xl mb-10"
                icon={close}
                onClick={handleHamburger}
              />
            </div>
            <nav>
              <ul className="text-left">
                <li className="text-xl my-6">
                  <span className="px-1 mx-2 ">
                    <FontAwesomeIcon icon={shoppingCart} />
                  </span>
                  <NavLink to="/brands">Store</NavLink>
                </li>
                <li className="text-xl my-6">
                  <span className="px-1 mx-2 ">
                    <FontAwesomeIcon icon={fire} />
                  </span>
                  <NavLink to="/hot">Hot</NavLink>
                </li>
                <li className="text-xl my-6">
                  <span className="px-1 mx-2 ">
                    <FontAwesomeIcon icon={filter} />
                  </span>
                  <NavLink to="/brands">Categories</NavLink>
                </li>
                <li className="text-xl my-6">
                  <span className="px-1 mx-2 ">
                    <FontAwesomeIcon icon={refresh} />
                  </span>
                  <NavLink to="/brands">Latest</NavLink>
                </li>
                <li className="text-xl my-6">
                  <div className="Flex  mx-2 justify-between">
                    <FontAwesomeIcon
                      className="p-3 pl-0 text-xl"
                      icon={twitter}
                    />
                    <FontAwesomeIcon
                      className="p-3 pl=0 text-xl"
                      icon={faFacebook}
                    />
                    <FontAwesomeIcon
                      className="p-3 pl=0 text-xl"
                      icon={instagram}
                    />
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        ) : (
          <header>
            <div className="flex m-0 my-10 justify-between items-center p-2">
              <img
                src={tosbpLogo}
                alt="logo"
                className="logo w-12 rounded-full"
              />
              <div className="flex  inputMonile bg-slate-100 p-3 rounded-2xl">
                <input
                  className="w-24 bg-slate-100 focus:outline-none  "
                  type="text"
                  placeholder="Search..."
                  onChange={handleSearch}
                  value={query}
                />
                <span
                  onClick={handleSearchRoute}
                  className=" rounded-l-2xl sm:pl-10 pl-16   text-center"
                >
                  <FontAwesomeIcon style={{ color: "#637d68" }} icon={search} />
                </span>
              </div>
              <div className="toggle" onClick={handleHamburger}>
                <FontAwesomeIcon
                  style={{ color: "#637d68" }}
                  icon={bars}
                  className="text-2xl font-bold"
                />
              </div>
            </div>
            <h1 className="headerH1 text-center tracking-widest text-2xl md:text-3xl  lg:text-5xl my-10">
              The Online Shopping At Best Price
            </h1>
          </header>
        )}
      </div>

      <div className="my-10">
        <div
          onClick={() => {
            navigate(`gift-ideas/holi`);
          }}
          className="w-full rounded-md"
        >
          <img src={holi} alt="" />
        </div>
      </div>

      {/*  gift ideas card section horizontal scrol */}
      <div className="mx-4 my-20">
        <h1 className="font-semibold text-white text-lg bg-black bg-opacity-40 p-2 rounded-lg w-fit">
          Gifts for Celebration
        </h1>

        <div className="flex flex-wrap justify-around ">
          {giftsForCelebrations.map((item: any, index: number) => {
            return (
              <div data-aos="fade-up" key={index}>
                <div
                  onClick={() => {
                    navigate(`gift-ideas/${item?.path}`);
                  }}
                  style={{
                    backgroundColor: "#637d68",
                    backgroundImage: `url(${item?.imgPath})`,
                  }}
                  className="flex items-end justify-start p-1 m-2 w-80 align-baseline self-baseline h-48 bg-cover bg-center bg-no-repeat rounded shadow-md cursor-pointer hover:shadow-lg"
                >
                  {/* title with transparent bg */}
                  <h1 className="font-bold text-white text-lg bg-black bg-opacity-50 p-2 rounded-lg">
                    ..
                  </h1>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mx-4 my-20">
        <h1 className="font-semibold text-white text-lg bg-black bg-opacity-40 p-2 rounded-lg w-fit">
          Gifts for your Loved Once
        </h1>

        <div className="flex flex-wrap justify-around ">
          {giftsForLovedOnes.map((item: any, index: number) => {
            return (
              <div data-aos="fade-up" key={index}>
                <div
                  onClick={() => {
                    navigate(`gift-ideas/${item?.path}`);
                  }}
                  style={{
                    backgroundColor: "#637d68",
                    backgroundImage: `url(${item?.imgPath})`,
                  }}
                  className="flex items-end justify-start p-1 m-2 w-80 align-baseline self-baseline h-48 bg-cover bg-center bg-no-repeat rounded shadow-md cursor-pointer hover:shadow-lg"
                >
                  {/* title with transparent bg */}
                  <h1 className="font-bold text-white text-lg bg-black bg-opacity-50 p-2 rounded-lg">
                    ...
                  </h1>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Hero;
