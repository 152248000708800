import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import AppLayout from './layout';
import PublicRouter from './routes/PublicRouter';
// import provider
import { Provider } from 'react-redux';
import store from './statesManagements/store';
function App() {
  return (
   <Provider store={store}>
     
      <Router>
    <AppLayout>
      <div className="hidden lg:block"></div> <PublicRouter />
    </AppLayout>
  </Router>
   </Provider>
  );
}

export default App;
