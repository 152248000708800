import { FETCH_CATEGORIES } from "./types";
import * as types from "../actionTypes"
import {environment} from "../../environment"



export const fetchBeautyCategories = async (dispatch) => {
    // console.log("fetcherrands")

    try {
        dispatch({type: types.FETCH_CATEGORIES.REQUEST})

        const  response = await fetch(`${process.env.BASE_URL}/products/category/Beauty/page/0`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })

        const data = await response.json();
        dispatch({
            type: types.FETCH_CATEGORIES.SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: types.FETCH_CATEGORIES.FAILURE,
            payload: error
        });
    }
    
}

export const fetchGiftIdeas = async (dispatch, title) => {
    try {
        dispatch({type: types.FETCH_GIFT_IDEAS.REQUEST})
        // console.log(env.BASE_URL)
        const res = await fetch(`${environment.baseUrl}/products/category/${title}/page/0`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })

        const data = await res.json();
        dispatch({
            type: types.FETCH_GIFT_IDEAS.SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: types.FETCH_GIFT_IDEAS.FAILURE,
            payload: error
        });
    }
}
export const fetchDynamicProducts = async (dispatch, title) => {
    try {
        dispatch({type: types.FETCH_DYNAMICPRODUCTS.REQUEST})
        // console.log(env.BASE_URL)
        const res = await fetch(`${environment.baseUrl}/products/category/${title}/page/0`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })

        const data = await res.json();
        dispatch({
            type: types.FETCH_DYNAMICPRODUCTS.SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: types.FETCH_DYNAMICPRODUCTS.FAILURE,
            payload: error
        });
    }
}

// fetch("http://152.67.5.243:8080/products/category/Beauty/page/0", {
//         headers: {
//             "Content-Type": "application/json",
//             "Accept": "application/json"
//         }
//     })
//     .then(res => res.json())
//     .then(data => {
//         // console.log("categories",data)
//         dispatch({
//             type: FETCH_CATEGORIES,
//             payload: data
//         });
//     }
//     );
// Compare this snippet from src\actions\postActions.js:
