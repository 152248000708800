import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faArrowRight, faBagShopping, faComputer, faHamburger, faHeadphones, faHospital, faPlateWheat, faSearch, faShirt, faShoePrints, faSprayCanSparkles, faStopwatch, faStopwatch20, faTv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { watch } from "fs";
import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "../homepage/footer";
import Header from "../homepage/Header";

type Props = {}

const Categories: React.FC<Props> = () => {
    const shirt = faShirt as IconProp;
    const face = faSprayCanSparkles as IconProp;
    const wheat = faPlateWheat as IconProp;
    const tv = faTv as IconProp;
    const shoe = faShoePrints as IconProp;
    const headphone = faHeadphones as IconProp;
    const watch = faStopwatch20 as IconProp;
    const bag = faBagShopping as IconProp;
    const computer = faComputer as IconProp;
    const hospital = faHospital as IconProp;
    const watch1 = faStopwatch as IconProp;
    const hamburger = faHamburger as IconProp;
    const search = faSearch as IconProp;
    const arrowRight = faArrowRight as IconProp;
    const arrowLeft = faArrowLeft as IconProp;
    return (
        <div className="">
            <Header />
            <div className="categories mt-10 p-5">
                <h1 className='font-bold text-xl pl-10'>Categories</h1>
                <div className='p-4 flex flex-row flex-wrap justify-evenly box-border items-center h-fit'>
                    <NavLink to="/fashion">
                        <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <FontAwesomeIcon className='my-4  px-10 text-blue-900 text-6xl' icon={shirt} />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>FASHION</h1>
                        </div>
                    </NavLink>
                    <NavLink to="/beauty">
                        <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <FontAwesomeIcon className='my-4 px-10 text-pink-600 text-6xl' icon={face} />
                            <hr />
                            <h1 className='font-bold text-md mt-2 px-1'>Beauty & Personal Care</h1>
                        </div>
                    </NavLink>

                    <NavLink to="/mobile">
                        <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <FontAwesomeIcon className='my-4 px-10 text-blue-600 text-6xl' icon={wheat} />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Home & Kitchen</h1>
                        </div>
                    </NavLink>

                    <NavLink to="/electronics">
                        <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <FontAwesomeIcon className='my-4 px-10 text-orange-600 text-6xl' icon={tv} />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Electronics</h1>
                        </div>
                    </NavLink>

                    <NavLink to="/shoes-handbags">
                        <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <FontAwesomeIcon className='my-4 px-10 text-slate-600 text-6xl' icon={shoe} />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>FootWare</h1>
                        </div>
                    </NavLink>

                    <NavLink to="/computer">
                        <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <FontAwesomeIcon className='my-4 px-10 text-amber-600 text-6xl' icon={headphone} />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Mobile Accessories</h1>
                        </div>
                    </NavLink>

                    <NavLink to="">
                        <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <FontAwesomeIcon className='my-4 px-10 text-teal-600 text-6xl' icon={watch} />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Accessories</h1>
                        </div>
                    </NavLink>

                    <NavLink to="/shoes-handbags">
                        <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <FontAwesomeIcon className='my-4 px-10 text-emerald-600 text-6xl' icon={bag} />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Bags</h1>
                        </div>
                    </NavLink>

                    <NavLink to="/computer">
                        <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <FontAwesomeIcon className='my-4 px-10 text-bluegray-600 text-6xl' icon={computer} />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Computer Accessories</h1>
                        </div>
                    </NavLink>

                    <NavLink to="/health">
                        <div className='flex cat-ctn my-5 w-48 h-48 py-4 border shadow-lg text-center  flex-col'>
                            <FontAwesomeIcon className='my-4 px-10 text-red-600 text-6xl' icon={hospital} />
                            <hr />
                            <h1 className='font-bold text-lg mt-2 px-10'>Health & Wellness</h1>
                        </div>
                    </NavLink>

                </div>
                <div className="p-10">
                    <h1 className="font-bold text-2xl my-4">Our Top Categories</h1>
                    <p>
                        We are a dedicated team of online shopping experts, every day we try our best to get you the most affordable shopping deals from top Indian eCommerce stores such as Amazon, Flipkart, Tata Cliq, Ajio, Pepperfry, and Myntra, etc.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Categories;