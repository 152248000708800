import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { fetchPosts } from '../../statesManagements/actions/postActions'
import UsePagination from "../toolkits/usePagination";
import Header from "../homepage/Header";
import { NavLink } from "react-router-dom";

import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    FacebookIcon,
    WhatsappIcon,
    TwitterIcon,
} from "react-share";
import Loader from '../toolkits/loader';
type Props = {}

const Search: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state: any) => state.posts);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(15);
    const [nextPage, setNextPage] = useState(0);
    const [searchValue, setSearchValue] = useState('123');

    useEffect(() => {
        fetchPosts(dispatch, nextPage);
    }
        , [])


    const handlePageChange = () => {
        setNextPage(nextPage + 1);
        fetchPosts(dispatch, nextPage);
        window.scrollTo({
            top: 240,
            behavior: 'smooth',
        });

    }
    const handlePageChange2 = () => {
        setNextPage(nextPage - 1);
        fetchPosts(dispatch, nextPage);
        window.scrollTo({
            top: 240,
            behavior: 'smooth',
        });
    }
    // handleChange
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value.toLowerCase());
        fetchPosts(dispatch, nextPage);
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selector.posts.slice(indexOfFirstItem, indexOfLastItem);
    const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);
    return (
        <div className=''>
            <Header />
            <div className='m-10 '>
                <p className='border cursor-pointer w-fit p-2 shadow'>
                    <NavLink to='/' className='text-center'>
                    Go To Home
                    </NavLink>
                </p>
            </div>
            
                    <div id='ctn' className='ctn flex justify-center items-center'>
                        {currentItems.map((post: any, index: any) => {
                            return (
                                <NavLink to={'/view_product/' + post.productURLId} className='cards px-3 mx-1  '>
                                    <div className=' flex justify-center items-center' >
                                        <div key={index.productId} className="flex flex-col items-center justify-center">
                                            <div className="bg-white img-ctn p-3 flex flex-col justify-center">
                                                <div className="off mb-1">
                                                <p className='text-sm font-bold tred text-red-500'>{Math.round((parseInt(post.dealPrice.toString().replace(/\D/g, '')) - parseInt(post.originalPrice.toString().replace(/\D/g, ''))) / parseInt(post.originalPrice.toString().replace(/\D/g, '')) * 100)}% off</p>
                                                </div>
                                            <img className='hover:shadow-2xl mx-2 imgs' src={post.imageUrls} alt="" />
                                            </div>
                                            <div className='cards-text uppercase text-center'>
                                                <h1 className='text-center font-semibold my-1 tracking-wider  rounded my-1 text-md font-base'>{post.brandName}</h1>
                                                <a title={post.productTitle} className='productTitle  text-sm my-2'>{post.productTitle.slice(0,45)}...</a>
                                                {/* <input type="text" className='productTitle  text-sm my-2' value={post.productTitle} /> */}
                                                <div className="flex  justify-between my-1">
                                                    {/* <p className='text-xs  text-gray-500'> <FontAwesomeIcon icon={watch1} className="text-xs mr-1" />{Math.round((Number(post.dealPostTime) / (1000 * 60 * 60)) % 24)} hours ago</p> */}
                                                    <p className='text-base lt line-through pb-1'>{post.originalPrice}</p>
                                                    <p className='font-bold text-sm' style={{ "color": "#637d68" }}>{post.dealPrice}</p>
                                                </div>
                                                <div className="flex justify-between my-1">
                                                    {/* <p className='text-xs font-bold  text-red-500'>{Math.round((parseInt(post.dealPrice.toString().replace(/\D/g, '')) - parseInt(post.originalPrice.toString().replace(/\D/g, ''))) / parseInt(post.originalPrice.toString().replace(/\D/g, '')) * 100)}% off</p> */}
                                                    {/* <p className='text-base line-through pb-1'>{post.originalPrice}</p> */}
                                                </div>
                                            </div>
                                            <div className='w-full'>

                                                <button style={{ "backgroundColor": "#7f9481" }} className='cards-btn w-full  shadow p-1 rounded text-white '><a href={post.productUrl} target="_blank">Get Offer</a></button>
                                            </div>
                                            {/* <div className="share flex my-2">
                                                <TwitterShareButton className='mx-1' url={post.productUrl} title={post.productTitle} hashtags={["eCommerce,amazon"]} >
                                                    <TwitterIcon size={20} round />
                                                </TwitterShareButton>
                                                <FacebookShareButton url={post.productUrl} quote={post.productTitle}>
                                                    <FacebookIcon size={20} round />
                                                </FacebookShareButton>
                                                <WhatsappShareButton className='mx-1' url={post.productUrl} title={post.productTitle}>
                                                    <WhatsappIcon size={20} round />
                                                </WhatsappShareButton>
                                            </div> */}

                                        </div>
                                    </div>
                                </NavLink>
                            )

                        }
                        )}


                    </div>
                
        </div>
    )
}


export default Search;