// 
// 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from 'react-share'
import { fetchPosts } from '../../statesManagements//actions/postActions'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import UsePagination from '../toolkits/usePagination'
import Header from '../homepage/Header'
import Footer from '../homepage/footer'


type Props = {}

const Grocery: React.FC<Props> = () => {
    const watch1 = faStopwatch as IconProp;
    const arrowRight = faArrowRight as IconProp;
    const arrowLeft = faArrowLeft as IconProp;
    const dispatch = useDispatch();
    const selector = useSelector((state: any) => state.posts);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(25);
    const [nextPage, setNextPage] = useState(2);
    const [searchValue, setSearchValue] = useState('123');

    useEffect(() => {
        fetchPosts(dispatch, nextPage);
        // fetchBrands(dispatch)
        // console.log("dp", dealPrice)
    }
        , [])


    const handlePageChange = () => {
        setNextPage(nextPage + 1);
        fetchPosts(dispatch, nextPage);
        window.scrollTo({
            top: 240,
            behavior: 'smooth',
        });

    }
    const handlePageChange2 = () => {
        setNextPage(nextPage - 1);
        fetchPosts(dispatch, nextPage);
        window.scrollTo({
            top: 240,
            behavior: 'smooth',
        });
    }
    // handleChange
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value.toLowerCase());
        fetchPosts(dispatch, nextPage);
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selector.posts.slice(indexOfFirstItem, indexOfLastItem);
    //slice first 20 trings

    const paginate = (pageNumber: React.SetStateAction<number>) => setCurrentPage(pageNumber);

    return (
        <div className=' p-5' style={{ "height": "" }}>
            <Header />
            {/*map currentItems equals Home & Kitchen or Home */}
            <div id='ctn' className='ctn mt-10 flex justify-center items-center'>

                {currentItems.filter(item => item.categories.includes('Grocery & Gourmet')).map(item => (
                    <NavLink to={`/view_product/${item.productURLId}`} key={item.id} className="cards mx-1 p-5">
                        <div className='flex justify-center items-center'>
                            <div key={item.productId} className="flex flex-col items-center justify-center">

                                <div className="bg-white img-ctn p-3 flex flex-col justify-center">
                                    <div className="off mb-1">
                                        <p className='text-sm font-bold tred text-red-500'>{Math.round((parseInt(item.dealPrice.toString().replace(/\D/g, '')) - parseInt(item.originalPrice.toString().replace(/\D/g, ''))) / parseInt(item.originalPrice.toString().replace(/\D/g, '')) * 100)}% off</p>
                                    </div>
                                    <img className='hover:shadow-2xl mx-2 imgs' src={item.imageUrls} alt="" />
                                </div>
                                <div className='cards-text uppercase text-center'>
                                    <h1 className='text-center font-semibold my-1 tracking-wider  rounded my-1 text-md font-base'>{item.brandName}</h1>
                                    <a title={item.productTitle} className='productTitle  text-sm my-2'>{item.productTitle.slice(0, 40)}...</a>
                                    {/* <input type="text" className='productTitle  text-sm my-2' value={post.productTitle} /> */}
                                    <div className="flex  justify-between my-1">
                                        {/* <p className='text-xs  text-gray-500'> <FontAwesomeIcon icon={watch1} className="text-xs mr-1" />{Math.round((Number(post.dealPostTime) / (1000 * 60 * 60)) % 24)} hours ago</p> */}
                                        <p className='text-base lt line-through pb-1'>{item.originalPrice}</p>
                                        <p className='font-bold text-sm' style={{ "color": "#637d68" }}>{item.dealPrice}</p>
                                    </div>
                                    <div className="flex justify-between my-1">
                                        {/* <p className='text-xs font-bold  text-red-500'>{Math.round((parseInt(post.dealPrice.toString().replace(/\D/g, '')) - parseInt(post.originalPrice.toString().replace(/\D/g, ''))) / parseInt(post.originalPrice.toString().replace(/\D/g, '')) * 100)}% off</p> */}
                                        {/* <p className='text-base line-through pb-1'>{post.originalPrice}</p> */}
                                    </div>
                                </div>
                                <div className='text-center'>

                                    {/* <button style={{ "backgroundColor": "#637d68" }} className='cards-btn w-40  shadow p-1.5 rounded text-white '><a href={post.productUrl} target="_blank">Get Offer</a></button> */}
                                </div>
                                {/* <div className="share flex my-2">
                                          <TwitterShareButton className='mx-1' url={item.productUrl} title={item.productTitle} hashtags={["eCommerce,amazon"]} >
                                              <TwitterIcon size={20} round />
                                          </TwitterShareButton>
                                          <FacebookShareButton url={item.productUrl} quote={item.productTitle}>
                                              <FacebookIcon size={20} round />
                                          </FacebookShareButton>
                                          <WhatsappShareButton className='mx-1' url={item.productUrl} title={item.productTitle}>
                                              <WhatsappIcon size={20} round />
                                          </WhatsappShareButton>
                                      </div> */}

                            </div>
                        </div>
                    </NavLink>

                ))}


            </div>
            <div className=" pagination p-4 flex items-center justify-center">
                <button className={`page-btn text-white mx-5 py-1 w-20 text-lg' ${nextPage < 1 ? "paginate-btn-fade mx-5 py-1 px-5 text-lg" : "paginate-btn mx-5 py-1 px-5 text-lg"}`} onClick={handlePageChange2} disabled={nextPage < 1}>
                    <FontAwesomeIcon icon={arrowLeft} className="text-lg" />
                </button>
                < UsePagination itemsPerPage={itemsPerPage} totalItems={selector.posts.length} paginate={paginate} />
                {/* button with click function handleChnage2, if nextpage is less than 2, disable button and set opacity to background color */}
                <button style={{ "backgroundColor": "#637d68" }} className='page-btn  text-white mx-5  py-1 w-20 text-lg' onClick={handlePageChange} >
                    <FontAwesomeIcon icon={arrowRight} className="text-lg" />
                </button>
            </div>
            <Footer />
        </div>
    )
}


export default Grocery;